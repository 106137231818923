import React from "react";
import { Svg, SvgProps } from '@lucky-chip/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M8 0.5C3.8675 0.5 0.5 3.8675 0.5 8C0.5 12.1325 3.8675 15.5 8 15.5C12.1325 15.5 15.5 12.1325 15.5 8C15.5 3.8675 12.1325 0.5 8 0.5ZM7.4375 5C7.4375 4.6925 7.6925 4.4375 8 4.4375C8.3075 4.4375 8.5625 4.6925 8.5625 5V8.75C8.5625 9.0575 8.3075 9.3125 8 9.3125C7.6925 9.3125 7.4375 9.0575 7.4375 8.75V5ZM8.69 11.285C8.6525 11.3825 8.6 11.4575 8.5325 11.5325C8.4575 11.6 8.375 11.6525 8.285 11.69C8.195 11.7275 8.0975 11.75 8 11.75C7.9025 11.75 7.805 11.7275 7.715 11.69C7.625 11.6525 7.5425 11.6 7.4675 11.5325C7.4 11.4575 7.3475 11.3825 7.31 11.285C7.2725 11.195 7.25 11.0975 7.25 11C7.25 10.9025 7.2725 10.805 7.31 10.715C7.3475 10.625 7.4 10.5425 7.4675 10.4675C7.5425 10.4 7.625 10.3475 7.715 10.31C7.895 10.235 8.105 10.235 8.285 10.31C8.375 10.3475 8.4575 10.4 8.5325 10.4675C8.6 10.5425 8.6525 10.625 8.69 10.715C8.7275 10.805 8.75 10.9025 8.75 11C8.75 11.0975 8.7275 11.195 8.69 11.285Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
