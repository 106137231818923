import { useEffect } from 'react';
import { useAppDispatch } from 'state';
import useActiveWeb3React from '../../../hooks/useActiveWeb3React';
import useRefresh from '../../../hooks/useRefresh';
import { useGames } from '../../../state/game/hooks';
import { loadGameTablesState, resetGameUserData } from '../../../state/game';

const usePollFlips = () => {
  const { dataLoaded, userDataLoaded, isLoading } = useGames()
  const { slowRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()

  useEffect(() => {
    if (!isLoading) {
      dispatch(loadGameTablesState({ account, isFullLoad: account && !userDataLoaded }))
    }
  }, [account, slowRefresh, dispatch, dataLoaded, userDataLoaded, isLoading])

  useEffect(() => {
    dispatch(resetGameUserData())
  }, [account, dispatch])
}

export default usePollFlips
