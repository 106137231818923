import BigNumber from 'bignumber.js';
import { multicallv2 } from 'utils/multicall';
import oracleAbi from 'config/abi/oracle.json';
import oracleUsdAbi from 'config/abi/oracle-usd.json';
import { getOracleAddress } from 'utils/addressHelpers';
import { PriceMap } from '../types';
import allTokens, { rewardTokenSymbols } from '../../config/constants/tokens';
import { getBigNumberFromEthers } from '../../utils/formatBalance';

const getOraclePrices = async (isLC: boolean): Promise<PriceMap> => {
  const address = getOracleAddress()
  const tokens = rewardTokenSymbols.map(symbol => allTokens[symbol])
  const multiplier = 10000
  const calls = tokens.map(token => ({
    address,
    name: isLC ? 'getQuantity' : 'getQuantityUSDT',
    params: [token.address, multiplier]
  }))
  const callResults = await multicallv2((isLC ? oracleAbi : oracleUsdAbi), calls)
  const priceMap: PriceMap = {}
  callResults.forEach((callResult, index) => {
    priceMap[tokens[index].address] = getBigNumberFromEthers(callResult.quantity).dividedBy(new BigNumber(multiplier)).toJSON()
  })
  return priceMap
}

export const getLCPrices = () => getOraclePrices(true)
export const getBUSDPrices = () => getOraclePrices(false)