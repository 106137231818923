import React from 'react'
import { HelpIcon, useTooltip, Box, BoxProps, Placement } from '@lucky-chip/uikit'
import styled from 'styled-components'
import { ExclamationIcon } from '../Icons'

interface Props extends BoxProps {
  text: string | React.ReactNode
  placement?: Placement
  exclamation?: boolean
}

const QuestionWrapper = styled.span`
  :hover,
  :focus {
    opacity: 0.7;
  }
`

const QuestionHelper: React.FC<Props> = ({ text, placement = 'right-end', exclamation = false, ...props }) => {
  const { targetRef, tooltip, tooltipVisible } = useTooltip(text, { placement, trigger: 'hover' })

  return (
    <Box display="inline-block" {...props}>
      {tooltipVisible && tooltip}
      <QuestionWrapper ref={targetRef}>
        {!exclamation && <HelpIcon color="textSubtle" width="16px" />}
        {exclamation && <ExclamationIcon color="textSubtle" width="16px" />}
      </QuestionWrapper>
    </Box>
  )
}

export default QuestionHelper
