import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'state';
import { simpleRpcProvider } from 'utils/providers';
import { setBlock } from '.';
import { State } from '../types';

const POLL_BLOCK_NUMBER_INTERVAL = 3000

export const usePollBlockNumber = () => {
  const timer = useRef(null)
  const dispatch = useAppDispatch()

  useEffect(() => {
    timer.current = setInterval(async () => {
      const block = await simpleRpcProvider.getBlockNumber()
      const timestamp = (await simpleRpcProvider.getBlock(block)).timestamp
      dispatch(setBlock({ block, timestamp }))
    }, POLL_BLOCK_NUMBER_INTERVAL)
    return () => clearInterval(timer.current)
  }, [dispatch, timer])
}

export const useBlock = () => {
  return useSelector((state: State) => state.block)
}

export const useBlockTimeStamp = () => {
  return useSelector((state: State) => state.block.timestamp)
}

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock)
}
