import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DiceReferralState } from 'state/types';
import { getDiceReferralsData } from './helpers';

const initialState: DiceReferralState = {}

// Thunks
export const initializeDiceReferrals = createAsyncThunk<DiceReferralState, string>('diceReferrals/initialize', getDiceReferralsData)

export const referralsSlice = createSlice({
  name: 'diceReferrals',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    // Initialize dice
    builder.addCase(initializeDiceReferrals.pending, (state, actions) => {
      return initialState
    })
    builder.addCase(initializeDiceReferrals.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
  },
})

export default referralsSlice.reducer
