import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ApiState, AprMap, BetMiningApiData } from 'state/types';
import { getBankAPRs, getBetMiningApiData, getFarmAPRs, getRankData } from './helpers';

const initialState: ApiState = {
  farmAPRs: {},
  bankAPRs: {},
  quantityRank: [],
  lpRank: [],
  bankerRank: [],
  playerRank: [],
  referrerRank: [],
  lpcommissionRank: [],
  betcommissionRank: [],
  activecntRank: [],
  firstprizeRank: [],
  secondprizeRank: [],
  thirdprizeRank: [],
  privateActiveCntRank: [],
  betAmountRank: [],
  dailyBetVolume: null,
  dailyRewardLC: null,
  myTotalReward: null,
  myVolume: null,
  pendingReward: null,
  proof: [],
  apr: null,
}

// Thunks
export const fetchFarmAPRs = createAsyncThunk<AprMap>('api/fetchFarmAPRs', getFarmAPRs)
export const fetchBankAPRs = createAsyncThunk<AprMap>('api/fetchBankAPRs', getBankAPRs)
type RankData = Pick<ApiState, 'quantityRank' | 'lpRank' | 'bankerRank' | 'playerRank' | 'referrerRank' | 'lpcommissionRank' | 'betcommissionRank' | 'activecntRank' | 'firstprizeRank' | 'secondprizeRank' | 'thirdprizeRank' | 'privateActiveCntRank' | 'betAmountRank'>
export const fetchRankData = createAsyncThunk<RankData>('api/fetchRanks', getRankData)
export const fetchBetMiningApiData = createAsyncThunk<BetMiningApiData, string>('api/fetchBetMiningApiData', getBetMiningApiData)

export const apiDataSlice = createSlice({
  name: 'api',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFarmAPRs.fulfilled, (state, action) => {
        return { ...state, farmAPRs: action.payload }
      })
    builder
      .addCase(fetchBankAPRs.fulfilled, (state, action) => {
        return { ...state, bankAPRs: action.payload }
      })
    builder
      .addCase(fetchRankData.fulfilled, (state, action) => {
        return { ...state, ...action.payload }
      })
    builder
      .addCase(fetchBetMiningApiData.pending, (state, action) => {
        return {
          ...state,
          dailyBetVolume: null,
          dailyRewardLC: null,
          myTotalReward: null,
          myVolume: null,
          pendingReward: null,
          apr: null,
        }
      })
    builder
      .addCase(fetchBetMiningApiData.fulfilled, (state, action) => {
        return { ...state, ...action.payload }
      })
  },
})


// Actions
export default apiDataSlice.reducer
