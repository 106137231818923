import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BonusState, SerializedBigNumber } from 'state/types';
import { getPowers, getRewards } from './helpers';

const initialState: BonusState = {
  totalPower: null,
  playerPower: null,
  bankPower: null,
  lpPower: null,
  referralsPower: null,
  diceRewards: {},
}

// Thunks
export type LuckyPowerInitialization = Pick<BonusState, 'totalPower' | 'playerPower' | 'bankPower' | 'lpPower' | 'referralsPower'>
export const fetchPowers = createAsyncThunk<LuckyPowerInitialization, string>('bonus/fetchPower', getPowers)

export const fetchRewards = createAsyncThunk<{ [key: number]: SerializedBigNumber }, string>('bonus/fetchRewards', getRewards)

export const bonusDataSlice = createSlice({
  name: 'bonus',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPowers.pending, (state => {
        return {
          ...state,
          totalPower: null,
          playerPower: null,
          bankPower: null,
          lpPower: null,
          referralsPower: null,
        }
      }))

    builder
      .addCase(fetchPowers.fulfilled, (state, action) => {
        return { ...state, ...action.payload }
      })
    builder
      .addCase(fetchRewards.pending, (state => {
        return {
          ...state,
          diceRewards: {},
        }
      }))

    builder
      .addCase(fetchRewards.fulfilled, (state, action) => {
        return { ...state, diceRewards: action.payload }
      })
  },
})


// Actions

export default bonusDataSlice.reducer
