import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { codeToName } from 'multicodec/src/maps';
import { BanksState, BankState } from 'state/types';
import { getBanksData } from './helpers';

const initialState: BanksState = {
  banks: [],
  dataLoaded: false,
  userDataLoaded: false,
}

// Thunks
export const initializeBanks = createAsyncThunk<BanksState, string>(
  'banks/initialize',
  async (account): Promise<BanksState> => {
    const banksData = await getBanksData(account)
    return {
      banks: banksData,
      dataLoaded: true,
      userDataLoaded: true,
    }
  },
)

export const fetchBanks = createAsyncThunk<{ [key: number]: BankState }, string>('dices/fetchBanks', getBanksData)

export const banksSlice = createSlice({
  name: 'banks',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    // Initialize dice
    builder.addCase(initializeBanks.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
    builder.addCase(fetchBanks.fulfilled, (state, action) => {
      return {
        ...state,
        banks: action.payload,
        dataLoaded: true,
        userDataLoaded: true,
      }
    })
  },
})

export default banksSlice.reducer
