import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RollerState } from 'state/types';
import { diceTableIds } from '../../config/constants/betTableConfigs';

const initialState: RollerState = {
  privateGame: diceTableIds.reduce((acc, diceId) => ({ ...acc, [diceId]: { rolling: false, target: 0 } }), {})
}

export const rollerSlice = createSlice({
  name: 'roller',
  initialState,
  reducers: {
    updateRollerState: (state, action: PayloadAction<{diceId: number, rolling: boolean, target: number }>) => {
      const { diceId, rolling, target } = action.payload
      const roller = state.privateGame[diceId]
      roller.rolling = rolling
      roller.target = target
    },
  },
})


// Actions
export const { updateRollerState } = rollerSlice.actions
export default rollerSlice.reducer
