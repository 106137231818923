import React from "react";
import { Svg, SvgProps } from '@lucky-chip/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="18" height="18" rx="9" fill="#FFD748"/>
      <path d="M8.76668 2.56668L5.24001 6.09335C5.10668 6.22668 5.10668 6.43335 5.24001 6.56668L6.10001 7.42668C6.23334 7.56002 6.44001 7.56002 6.57335 7.42668L8.76668 5.23335C8.90001 5.10001 9.10668 5.10001 9.24001 5.23335L11.4333 7.42668C11.5667 7.56002 11.7733 7.56002 11.9067 7.42668L12.7667 6.56668C12.9 6.43335 12.9 6.22668 12.7667 6.09335L9.24001 2.56668C9.10668 2.44001 8.89334 2.44001 8.76668 2.56668Z" fill="white"/>
      <path d="M8.76668 15.4333L5.24001 11.9067C5.10668 11.7733 5.10668 11.5667 5.24001 11.4333L6.10001 10.5733C6.23334 10.44 6.44001 10.44 6.57335 10.5733L8.76668 12.7667C8.90001 12.9 9.10668 12.9 9.24001 12.7667L11.4333 10.5733C11.5667 10.44 11.7733 10.44 11.9067 10.5733L12.7667 11.4333C12.9 11.5667 12.9 11.7733 12.7667 11.9067L9.24001 15.4333C9.10668 15.56 8.89334 15.56 8.76668 15.4333Z" fill="white"/>
      <path d="M14.4001 7.73334L15.4267 8.76C15.56 8.89334 15.56 9.1 15.4267 9.23334L14.4001 10.26C14.2667 10.3933 14.06 10.3933 13.9267 10.26L12.9 9.23334C12.7667 9.1 12.7667 8.89334 12.9 8.76L13.9267 7.73334C14.06 7.60667 14.2734 7.60667 14.4001 7.73334Z" fill="white"/>
      <path d="M4.06667 7.73334L5.09334 8.76C5.22667 8.89334 5.22667 9.1 5.09334 9.23334L4.06667 10.26C3.93334 10.3933 3.72667 10.3933 3.59334 10.26L2.56667 9.23334C2.43334 9.1 2.43334 8.89334 2.56667 8.76L3.59334 7.73334C3.72667 7.60667 3.94001 7.60667 4.06667 7.73334Z" fill="white"/>
      <path d="M9.23332 7.58666L10.4133 8.76666C10.5466 8.9 10.5466 9.10666 10.4133 9.24L9.23332 10.42C9.09999 10.5533 8.89332 10.5533 8.75998 10.42L7.57998 9.24C7.44665 9.10666 7.44665 8.9 7.57998 8.76666L8.75998 7.58666C8.89332 7.45333 9.10665 7.45333 9.23332 7.58666Z" fill="white"/>
    </Svg>

  );
};

export default Icon;
