import { getBigNumberFromEthers } from '../../utils/formatBalance';
import { getLuckyPowerContract } from '../../utils/contractHelpers';
import { diceTableIds, allTableConfigs } from '../../config/constants/betTableConfigs';

export const getPowers = async (account: string): Promise<any> => {
  if (!account) {
    return {}
  }
  const luckyPowerContract = getLuckyPowerContract()
  const {
    quantity,
    lpQuantity,
    bankerQuantity,
    playerQuantity,
    referrerQuantity
  } = await luckyPowerContract.userInfo(account)
  return {
    totalPower: getBigNumberFromEthers(quantity).toString(),
    lpPower: getBigNumberFromEthers(lpQuantity).toString(),
    playerPower: getBigNumberFromEthers(playerQuantity).toString(),
    bankPower: getBigNumberFromEthers(bankerQuantity).toString(),
    referralsPower: getBigNumberFromEthers(referrerQuantity).toString(),
  }
}

export const getRewards = async (account: string): Promise<{[key: number]: SerializedBigNumber }> => {
  if (!account) {
    return []
  }
  const luckyPowerContract = getLuckyPowerContract()
  const [tokens, amounts] = await luckyPowerContract.pendingRewards(account)
  const result = {}
  diceTableIds.forEach(diceId => {
    const token = allTableConfigs[diceId].depositToken.address
    const index = tokens.findIndex(t => t.toLowerCase() === token.toLowerCase())
    if (index !== -1) {
      result[diceId] = getBigNumberFromEthers(amounts[index]).toString()
    }
  })
  return result
}
