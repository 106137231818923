import { ReferralState } from '../types'
import { getBigNumberFromEthers } from '../../utils/formatBalance'
import { getReferralContract } from '../../utils/contractHelpers'

export const getReferralsData = async (account: string): Promise<ReferralState> => {
  const referralContract = getReferralContract()
  const referralsCount = await referralContract.referralsCount(account)
  const data = await referralContract.referrerInfo(account)
  return {
    lpCommission: getBigNumberFromEthers(data.lpCommission).toString(),
    pendingLpCommission: getBigNumberFromEthers(data.pendingLpCommission).toString(),
    betCommission: getBigNumberFromEthers(data.betCommission).toString(),
    pendingBetCommission: getBigNumberFromEthers(data.pendingBetCommission).toString(),
    rankCommission: getBigNumberFromEthers(data.rankCommission).toString(),
    pendingRankCommission: getBigNumberFromEthers(data.pendingRankCommission).toString(),
    referralsCount: getBigNumberFromEthers(referralsCount).toNumber(),
  }
}