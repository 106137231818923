import React, { useMemo } from 'react';
import BigNumber from 'bignumber.js';
import { Box, LinkExternal, Menu as UikitMenu } from '@lucky-chip/uikit';
import { languageList } from 'config/localization/languages';
import { useTranslation } from 'contexts/Localization';
import useTheme from 'hooks/useTheme';
import { usePriceLCUsdt } from 'state/farms/hooks';
import config from './config';
import UserMenu from './UserMenu';
import GlobalSettings from './GlobalSettings';
import { useMarketData } from '../../state/market/hooks';
import { formatAsKM } from '../../utils/formatBalance';
import { DEFAULT_TOKEN_DECIMAL, IS_LUCKY_SEASON } from '../../config';
import { useBanks } from '../../state/bank/hooks';
import { usePriceData } from '../../state/oracle/hooks';
import { getLCAddress } from '../../utils/addressHelpers';
import { ALL_BANKS } from '../../config/constants/betTableConfigs';

const Menu = (props) => {
  const { isDark, toggleTheme } = useTheme()
  const lcPriceUsd = usePriceLCUsdt()
  const lcPrice = useMemo(() => {
    if (IS_LUCKY_SEASON) {
      return 'Coming Soon'
    }
    if (lcPriceUsd) {
      return `$${lcPriceUsd.toNumber()}`
    }
    return null
  }, [lcPriceUsd])
  const { usdPriceMap } = usePriceData()
  const { currentLanguage, setLanguage, t } = useTranslation()
  const { lcSupply } = useMarketData()
  const { banks, dataLoaded: banksDataLoaded } = useBanks()

  const lcSupplyLabel = useMemo(() => {
    if (IS_LUCKY_SEASON) {
      return 'Coming Soon'
    }
    if (lcSupply) {
      return formatAsKM(new BigNumber(lcSupply).dividedBy(DEFAULT_TOKEN_DECIMAL).toNumber())
    }
    return null
  }, [lcSupply])
  const lcMktCapLabel = useMemo(() => {
    if (IS_LUCKY_SEASON) {
      return 'Coming Soon'
    }
    if (lcSupply && lcPriceUsd) {
      const supply = new BigNumber(lcSupply)
      const v = formatAsKM(supply.multipliedBy(lcPriceUsd).dividedBy(DEFAULT_TOKEN_DECIMAL).toNumber())
      return `$${v}`
    }
    return null
  }, [lcSupply, lcPriceUsd])
  const TVLLabel = useMemo(() => {
    if (banksDataLoaded) {
      let tvl = new BigNumber(0);
      ALL_BANKS.forEach(BANK => {
        const price = new BigNumber(usdPriceMap[BANK.depositToken.address])
        tvl = tvl.plus(new BigNumber(banks[BANK.tableId].bankerTvl).multipliedBy(price))
      })
      const v = formatAsKM(new BigNumber(tvl).toNumber())
      return `$${v}`
    }
    return null
  }, [banks, banksDataLoaded, usdPriceMap])
  const lcBuyLink = useMemo(() => {
    const address = getLCAddress()
    return `https://pancakeswap.finance/swap?inputCurrency=BNB&outputCurrency=${address}`
  }, [])

  const RugDoc = <Box mx="-10px" mt="5px">
    <img src="/images/rug-doc.png" width="100%" alt="rug-doc"/>
  </Box>

  return (
    <UikitMenu
      userMenu={<UserMenu />}
      globalMenu={<GlobalSettings />}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={currentLanguage.code}
      langs={languageList}
      setLang={setLanguage}
      lcPriceUsd={lcPrice}
      lcSupply={lcSupplyLabel}
      lcMktCap={lcMktCapLabel}
      TVL={TVLLabel}
      links={config(t)}
      logoImage="/logo.png"
      certikLink="https://www.certik.org/projects/lucky-chip"
      additionalFooter={RugDoc}
      buyLCLink={<LinkExternal href={lcBuyLink} color="#c5b462" fontSize="15px" style={{ fontWeight: 500 }}>Buy LC at Pancake&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</LinkExternal>}
      {...props}
    />
  )
}

export default Menu
