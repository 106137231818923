import { ThunkAction } from 'redux-thunk';
import { AnyAction } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { DeserializedFarmConfig, SerializedFarmConfig, BetTableType } from 'config/constants/types';

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, State, unknown, AnyAction>

export interface BigNumberToJson {
  type: 'BigNumber'
  hex: string
}

export type SerializedBigNumber = string

interface SerializedFarmUserData {
  allowance: string
  tokenBalance: string
  stakedBalance: string
  earnings: string
  claimRatio: number
  nextWithdrawUntil: number
}

export interface DeserializedFarmUserData {
  allowance: BigNumber
  tokenBalance: BigNumber
  stakedBalance: BigNumber
  earnings: BigNumber
  claimRatio: number
  nextWithdrawUntil: number
}

export interface SerializedFarm extends SerializedFarmConfig {
  tokenPriceBusd?: string
  quoteTokenPriceBusd?: string
  tokenAmountTotal?: SerializedBigNumber
  lpTotalInQuoteToken?: SerializedBigNumber
  lpTotalSupply?: SerializedBigNumber
  tokenPriceVsQuote?: SerializedBigNumber
  poolWeight?: SerializedBigNumber
  userData?: SerializedFarmUserData
}

export interface DeserializedFarm extends DeserializedFarmConfig {
  tokenPriceBusd?: string
  quoteTokenPriceBusd?: string
  tokenAmountTotal?: BigNumber
  lpTotalInQuoteToken?: BigNumber
  lpTotalSupply?: BigNumber
  tokenPriceVsQuote?: BigNumber
  poolWeight?: BigNumber
  userData?: DeserializedFarmUserData
}

// Slices states

export interface IBetTableState {
  public: GamePublicData
  user: GameUserData
}

export interface SerializedFarmsState {
  data: SerializedFarm[]
  loadArchivedFarmsData: boolean
  userDataLoaded: boolean
}

export interface DeserializedFarmsState {
  data: DeserializedFarm[]
  loadArchivedFarmsData: boolean
  userDataLoaded: boolean
}

// Block

export interface BlockState {
  currentBlock: number
  initialBlock: number
  timestamp: number
}

export interface RankItem {
  address: string
  value: number
}

export interface BankState {
  paused: boolean
  bankerTvl: number
  bankerCap: number
  withdrawFeeRatio: number
  canWithdrawTokenAmount: SerializedBigNumber
}

export interface BanksState {
  banks: { [key: number]: BankState }
  dataLoaded: boolean
  userDataLoaded: boolean
}

export interface PriceMap {
  [key: string]: SerializedBigNumber
}

export interface LotteryInfo {
  totalLottery: SerializedBigNumber
  latestLottery: SerializedBigNumber
  myLottery: SerializedBigNumber
  latestPlayers: string[]
}

export interface LotteryState {
  lotteryInfos: { [key: string]: LotteryInfo }
  myTotalLottery: SerializedBigNumber
  accReward: SerializedBigNumber
  luckierReward: number
  topReward: number
  luckyReward: number
}

export interface HomeState {
  luckyPower: SerializedBigNumber
  bettingVolume: SerializedBigNumber
  playBonus: SerializedBigNumber
  bankerTVL: SerializedBigNumber
  bankerBonus: SerializedBigNumber
  referralsEarning: SerializedBigNumber
  referralsBonus: SerializedBigNumber
  farmTVL: SerializedBigNumber
  farmBonus: SerializedBigNumber
  luckyBonuses: PriceMap
}

export interface AprMap {
  [key: string]: number
}

export interface OraclePriceState {
  lcPriceMap: PriceMap // price map calculated in LC. e.g. 1ETH equals 150LC, 1LC equals 1LC, 1BUSD equals 10LC
  usdPriceMap: PriceMap // price map calculated in BUSD. e.g. 1ETH equals 4500BUSD,1LC equals 0.1BUSD, 1BUSD equal 1BUSD
}

export interface MarketState {
  lcSupply: SerializedBigNumber
  tvl: SerializedBigNumber
}

export interface BetMiningDice {
  diceId: BetTableType
  APR: number
  miningRate: number
  currentMiningVolume: SerializedBigNumber
  myBetMiningVolume: SerializedBigNumber
  totalLCMined: SerializedBigNumber
  myLCMined: SerializedBigNumber
}

export interface BetMiningState {
  tables: { [key: number] :BetMiningDice }
}

export interface ReferralState {
  betCommission: SerializedBigNumber
  pendingBetCommission: SerializedBigNumber
  lpCommission: SerializedBigNumber
  pendingLpCommission: SerializedBigNumber
  rankCommission: SerializedBigNumber
  pendingRankCommission: SerializedBigNumber
  referralsCount: number
}

export interface ReferrerInfo {
  accCommission: SerializedBigNumber
  pendingCommission: SerializedBigNumber
  referralsCount: number
}

export interface DiceReferralState {
  [key: number]: ReferrerInfo
}

export interface BonusState {
  totalPower: SerializedBigNumber
  playerPower: SerializedBigNumber
  bankPower: SerializedBigNumber
  lpPower: SerializedBigNumber
  referralsPower: SerializedBigNumber
  diceRewards: { [key: number]: SerializedBigNumber }
}

// API response
export interface ApiState {
  farmAPRs: AprMap
  bankAPRs: AprMap
  quantityRank: RankItem[]
  lpRank: RankItem[]
  bankerRank: RankItem[]
  playerRank: RankItem[]
  referrerRank: RankItem[]
  lpcommissionRank: RankItem[]
  betcommissionRank: RankItem[]
  activecntRank: RankItem[]
  firstprizeRank: RankItem[]
  secondprizeRank: RankItem[]
  thirdprizeRank: RankItem[]
  privateActiveCntRank: RankItem[]
  betAmountRank: RankItem[]
  dailyRewardLC: number
  dailyBetVolume: number
  myVolume: number
  myTotalReward: number
  pendingReward: number
  proof: string[]
  apr: number
}

export interface BetMiningApiData {
  dailyBetVolume: number
  dailyRewardLC: number
  myTotalReward: number
  myVolume: number
  proof: string[]
  pendingReward: number
  apr: number
}

// Roller state
export interface RollerState {
  privateGame: { [key: number]: { rolling: boolean, target: number } }
}

export interface Bet {
  round: number // epooch or blockNumber
  betId?: number
  betAmount: SerializedBigNumber
  winAmount: SerializedBigNumber
  finalNumber: number // isHead as well
  modulo: number
  mask: number
  rollUnder: number
  isSettled: boolean // private game only
  maxBetAmount?: SerializedBigNumber
  bankerAmount?: SerializedBigNumber
}

export interface BetReports {
  private: { [key: number]: boolean }
}

// game state

export interface GamePublicData {
  paused: boolean
  allBets: Bet[]
  allBetCount: number
  gapRate: number
  totalRate: number
  playerEndBlock: number
  bankerEndBlock: number
  offChainFeeAmount: SerializedBigNumber
  onChainFeeAmount: SerializedBigNumber
  bankerAmount: SerializedBigNumber
  minBetAmount: SerializedBigNumber
  maxBetRatio: number
}

export interface GameUserData {
  userBets: Bet[]
  userBetCount: number
  withdrawFeeRatio: number
  canWithdrawTokenAmount: SerializedBigNumber
  lastTxHash?: string
}

export interface GameTableState {
  public: GamePublicData
  user: GameUserData
  betReports: BetReports
}

export interface GamesState {
  tables: { [key: number]: GameTableState }
  dataLoaded: boolean
  userDataLoaded: boolean
  isLoading: boolean
  currentGameTokenId: number
  currentTableId: BetTableType
}

// WhiteList state
export interface WhiteListState {
  pendingLC: SerializedBigNumber
  bnbBalance: SerializedBigNumber
  minAmount: SerializedBigNumber
  maxAmount: SerializedBigNumber
  lcPrice: SerializedBigNumber
  isWhitelisted: boolean
}

export interface Airdrop {
  total: number
  claimable: number
  flipPlayed: boolean
  dicePlayed: boolean
  show?: boolean
}

// Airdrop state
export interface AirdropState {
  main: Airdrop
  player: Airdrop
  apeswap: Airdrop
  ama: Airdrop
}

export interface AirdropNotifState {
  annon: boolean
  user: boolean
}


// Global state
export interface State {
  block: BlockState
  farms: SerializedFarmsState
  banks: BanksState
  home: HomeState
  oracle: OraclePriceState
  betMining: BetMiningState
  referrals: ReferralState
  diceReferrals: DiceReferralState
  bonus: BonusState
  market: MarketState
  api: ApiState
  lottery: LotteryState
  roller: RollerState
  whitelist: WhiteListState
  airdrop: AirdropState
  airdropNotif: AirdropNotifState
  games: GamesState
}

export enum LotteryCategory {
  Top = 'TOP',
  Luckier = 'LUCKIER',
  Lucky = 'LUCKY',
}
