import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OraclePriceState, PriceMap } from 'state/types';
import { getBUSDPrices, getLCPrices } from './helpers';

const initialState: OraclePriceState = {
  lcPriceMap: {},
  usdPriceMap: {},
}

// Thunks
export const fetchLCPrices = createAsyncThunk<PriceMap>('oracle/fetchLCPrices', getLCPrices)
export const fetchBUSDPrices = createAsyncThunk<PriceMap>('oracle/fetchBUSDPrices', getBUSDPrices)

export const oracleDataSlice = createSlice({
  name: 'oracle',
  initialState,
  reducers: {
    setLCPriceMap: (state, action: PayloadAction<PriceMap>) => {
      state.lcPriceMap = action.payload
    },
    setUSDPriceMap: (state, action: PayloadAction<PriceMap>) => {
      state.usdPriceMap = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLCPrices.fulfilled, (state, action) => {
        return { ...state, lcPriceMap: action.payload }
      })

    builder
      .addCase(fetchBUSDPrices.fulfilled, (state, action) => {
        return { ...state, usdPriceMap: action.payload }
      })
  },
})


// Actions
export const { setLCPriceMap, setUSDPriceMap } = oracleDataSlice.actions

export default oracleDataSlice.reducer
