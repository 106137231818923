import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'state';
import { useWeb3React } from '@web3-react/core';
import BigNumber from 'bignumber.js';
import { BIG_ZERO } from 'utils/bigNumber';
import { getBalanceAmount } from 'utils/formatBalance';
import { farmsConfig } from 'config/constants';
import useRefresh from 'hooks/useRefresh';
import { deserializeToken } from 'state/user/hooks/helpers';
import { fetchFarmsPublicDataAsync, fetchFarmUserDataAsync, nonArchivedFarms } from '.';
import { DeserializedFarm, DeserializedFarmsState, DeserializedFarmUserData, SerializedFarm, State } from '../types';
import { fetchBUSDPrices, fetchLCPrices } from '../oracle';
import { usePriceData } from '../oracle/hooks';
import { getLCAddress } from '../../utils/addressHelpers';
import { fetchMarket } from '../market';
import { initializeBanks } from '../bank';
import { useBanks } from '../bank/hooks';
import { useInitialBlock } from '../block/hooks';
import { fetchBankAPRs, fetchBetMiningApiData, fetchFarmAPRs, fetchRankData } from '../api';

const deserializeFarmUserData = (farm: SerializedFarm): DeserializedFarmUserData => {
  return {
    allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
    tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
    stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
    earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
    claimRatio: farm.userData ? farm.userData.claimRatio : 0,
    nextWithdrawUntil: farm.userData ? farm.userData.nextWithdrawUntil: 0,
  }
}

const deserializeFarm = (farm: SerializedFarm): DeserializedFarm => {
  const { lpAddresses, lpSymbol, pid, dual, multiplier, isCommunity, quoteTokenPriceBusd, tokenPriceBusd, type } = farm

  return {
    lpAddresses,
    lpSymbol,
    pid,
    dual,
    multiplier,
    isCommunity,
    quoteTokenPriceBusd,
    tokenPriceBusd,
    name: farm.name,
    token: deserializeToken(farm.token),
    bankType: farm.bankType,
    quoteToken: deserializeToken(farm.quoteToken),
    userData: deserializeFarmUserData(farm),
    tokenAmountTotal: farm.tokenAmountTotal ? new BigNumber(farm.tokenAmountTotal) : BIG_ZERO,
    lpTotalInQuoteToken: farm.lpTotalInQuoteToken ? new BigNumber(farm.lpTotalInQuoteToken) : BIG_ZERO,
    lpTotalSupply: farm.lpTotalSupply ? new BigNumber(farm.lpTotalSupply) : BIG_ZERO,
    tokenPriceVsQuote: farm.tokenPriceVsQuote ? new BigNumber(farm.tokenPriceVsQuote) : BIG_ZERO,
    poolWeight: farm.poolWeight ? new BigNumber(farm.poolWeight) : BIG_ZERO,
    type,
  }
}

export const usePollFarmsPublicData = (includeArchive = false) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchFarmsPublicDataAsync(pids))
  }, [includeArchive, dispatch, slowRefresh])
}

export const usePollFarmsWithUserData = (includeArchive = false) => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    const farmsToFetch = includeArchive ? farmsConfig : nonArchivedFarms
    const pids = farmsToFetch.map((farmToFetch) => farmToFetch.pid)

    dispatch(fetchFarmsPublicDataAsync(pids))

    if (account) {
      dispatch(fetchFarmUserDataAsync({ account, pids }))
    }
  }, [includeArchive, dispatch, slowRefresh, account])
}

/**
 * Fetches the "core" farm data used globally
 * 0 = LuckyChipDice
 * 1 = LuckyWBNB
 * 2 = LuckyBUSD
 * 3 = LuckyBTCB
 * 4 = LuckyETH
 * 5 = LC-BNB LP
 */
export const usePollCoreFarmData = () => {
  const dispatch = useAppDispatch()
  const { fastRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchFarmsPublicDataAsync([0,1,2,3,4,5]))
  }, [dispatch, fastRefresh])
}

export const usePollAPIData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { account } = useWeb3React()

  useEffect(() => {
    dispatch(fetchFarmAPRs())
    dispatch(fetchBankAPRs())
    dispatch(fetchRankData())
  }, [dispatch, slowRefresh])

  useEffect(() => {
    dispatch(fetchBetMiningApiData(account))
  }, [dispatch, slowRefresh, account])
}

export const usePollMarketData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()
  const { dataLoaded: banksDataLoaded } = useBanks()
  const initialBlock = useInitialBlock()
  const { account } = useWeb3React()

  useEffect(() => {
    if (initialBlock > 0) {
      if (!banksDataLoaded) {
        dispatch(initializeBanks(account))
      }
    }
  }, [initialBlock, dispatch, banksDataLoaded, account])

  useEffect(() => {
    dispatch(fetchMarket())
  }, [dispatch, slowRefresh])
}

export const usePollOraclePriceData = () => {
  const dispatch = useAppDispatch()
  const { slowRefresh } = useRefresh()

  useEffect(() => {
    dispatch(fetchLCPrices())
    dispatch(fetchBUSDPrices())
  }, [dispatch, slowRefresh])
}

export const useFarms = (): DeserializedFarmsState => {
  const farms = useSelector((state: State) => state.farms)
  const deserializedFarmsData = farms.data.map(deserializeFarm)
  const { loadArchivedFarmsData, userDataLoaded } = farms
  return {
    loadArchivedFarmsData,
    userDataLoaded,
    data: deserializedFarmsData,
  }
}

export const useFarmFromPid = (pid: number): DeserializedFarm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
  return deserializeFarm(farm)
}

export const useFarmFromLpSymbol = (lpSymbol: string): DeserializedFarm => {
  const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
  return deserializeFarm(farm)
}

export const useFarmUser = (pid): DeserializedFarmUserData => {
  const { userData } = useFarmFromPid(pid)
  const { allowance, tokenBalance, stakedBalance, earnings, claimRatio, nextWithdrawUntil } = userData
  return {
    allowance,
    tokenBalance,
    stakedBalance,
    earnings,
    claimRatio,
    nextWithdrawUntil,
  }
}

// Return the base token price for a farm, from a given pid
export const useBusdPriceFromPid = (pid: number): BigNumber => {
  const farm = useFarmFromPid(pid)
  return farm && new BigNumber(farm.tokenPriceBusd)
}

export const useLpTokenPrice = (symbol: string) => {
  const farm = useFarmFromLpSymbol(symbol)
  const farmTokenPriceInUsd = useBusdPriceFromPid(farm.pid)
  let lpTokenPrice = BIG_ZERO

  if (farm.lpTotalSupply.gt(0) && farm.lpTotalInQuoteToken.gt(0)) {
    // Total value of base token in LP
    const valueOfBaseTokenInFarm = farmTokenPriceInUsd.times(farm.tokenAmountTotal)
    // Double it to get overall value in LP
    const overallValueOfAllTokensInFarm = valueOfBaseTokenInFarm.times(2)
    // Divide total value of all tokens, by the number of LP tokens
    const totalLpTokens = getBalanceAmount(farm.lpTotalSupply)
    lpTokenPrice = overallValueOfAllTokensInFarm.div(totalLpTokens)
  }

  return lpTokenPrice
}

// /!\ Deprecated , use the BUSD hook in /hooks

export const usePriceLCUsdt = (): BigNumber => {
  const { usdPriceMap } = usePriceData()
  const lcContractAddress = getLCAddress()
  return usdPriceMap[lcContractAddress] ? new BigNumber(usdPriceMap[lcContractAddress]) : null
}
