import BigNumber from 'bignumber.js';
import { Token } from '@luckychip/luckychip-sdk';

export type TranslatableText =
  | string
  | {
      key: string
      data?: {
        [key: string]: string | number
      }
    }
export interface Address {
  97?: string
  56: string
}

export interface SerializedToken {
  chainId: number
  address: string
  decimals: number
  symbol?: string
  name?: string
  projectLink?: string
}

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'BINANCE' = 'Binance', // Pools using native BNB behave differently than pools using a token
  'AUTO' = 'Auto',
}

export enum FarmPid {
  LC_BNB_LP = 0,
  LC_USDT = 1,
  LC_DICE_TOKEN = 2,
  BNB_DICE_TOKEN = 3,
  USDT_DICE_TOKEN = 4,
  BNB_FLIP_TOKEN = 6,
  LC_GAME_TOKEN = 7,
  BNB_GAME_TOKEN = 8,
}

interface FarmConfigBaseProps {
  pid: number
  lpSymbol: string
  lpAddresses: Address
  multiplier?: string
  name: string
  isCommunity?: boolean
  dual?: {
    rewardPerBlock: number
    earnLabel: string
    endBlock: number
  }
  type: FarmType
  bankType: GameType
}

export interface SerializedFarmConfig extends FarmConfigBaseProps {
  token: SerializedToken
  quoteToken: SerializedToken
}

export interface DeserializedFarmConfig extends FarmConfigBaseProps {
  token: Token
  quoteToken: Token
}

export type Images = {
  lg: string
  md: string
  sm: string
  ipfs?: string
}

export type PageMeta = {
  title: string
  description?: string
  image?: string
}

// Farm Auction
export interface FarmAuctionBidderConfig {
  account: string
  farmName: string
  tokenAddress: string
  quoteToken: Token
  tokenName: string
  projectSite?: string
  lpAddress?: string
}

// Note: this status is slightly different compared to 'status' comfing
// from Farm Auction smart contract
export enum AuctionStatus {
  ToBeAnnounced, // No specific dates/blocks to display
  Pending, // Auction is scheduled but not live yet (i.e. waiting for startBlock)
  Open, // Auction is open for bids
  Finished, // Auction end block is reached, bidding is not possible
  Closed, // Auction was closed in smart contract
}

export interface Auction {
  id: number
  status: AuctionStatus
  startBlock: number
  startDate: Date
  endBlock: number
  endDate: Date
  auctionDuration: number
  initialBidAmount: number
  topLeaderboard: number
  leaderboardThreshold: BigNumber
}

export interface BidderAuction {
  id: number
  amount: BigNumber
  claimed: boolean
}

export interface Bidder extends FarmAuctionBidderConfig {
  position?: number
  isTopPosition: boolean
  samePositionAsAbove: boolean
  amount: BigNumber
}

export interface ConnectedBidder {
  account: string
  isWhitelisted: boolean
  bidderData?: Bidder
}

export enum GameType {
  Dice = 'Dice',
  Flip = 'Flip',
  Game = 'Game',
}

export enum BetTableType {
  OLD_LC_DICE = 0,
  OLD_BNB_DICE = 1,
  OLD_USDT_DICE = 2,
  OLD_BNB_FLIP = 3,
  LC_DICE = 4,
  BNB_DICE = 5,
  BANANA_DICE = 6,
  LC_FLIP = 7,
  BNB_FLIP = 8,
  BANANA_FLIP = 9,
  BABY_DICE = 10,
  BABY_FLIP = 11,
}

export enum GameTokenId {
  LC_GAME = 0,
  BNB_GAME = 1,
  USDT_GAME = 2,
}

export interface BetTableConfig {
  tableId: number
  gameType: GameType
  gameTokenId: GameTokenId
  depositToken: Token
  gameToken: Token
  contractAddress: Address
  diceReferralContractAddress?: Address
  isSwapBet?: boolean
  swapToken?: Token
  swapRouterId?: number
  pairAddress?: Address
  isToken0?: boolean
}

export interface GameConfig {
  gameTokenId: GameTokenId
  depositToken: Token
  gameToken: Token
  contractAddress: Address
}

export enum FarmType {
  None = 'NONE',
  Liquidity = 'LIQUIDITY',
  Bank = 'BANK',
}
