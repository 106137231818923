import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { LotteryCategory, LotteryInfo, LotteryState } from 'state/types';
import { getLotteryInfo } from './helpers';

const emptyLotteryInfo: LotteryInfo = {
  totalLottery: null,
  latestLottery: null,
  myLottery: null,
  latestPlayers: [],
}

const initialState: LotteryState = {
  lotteryInfos: {
    [LotteryCategory.Top]: emptyLotteryInfo,
    [LotteryCategory.Luckier]: emptyLotteryInfo,
    [LotteryCategory.Lucky]: emptyLotteryInfo,
  },
  myTotalLottery: null,
  luckierReward: 0,
  topReward: 0,
  luckyReward: 0,
  accReward: null,
}

// Thunks
export const fetchLotteryInfo = createAsyncThunk<LotteryState, string>('lottery/fetchLottery', getLotteryInfo)

export const lotteryDataSlice = createSlice({
  name: 'lottery',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLotteryInfo.fulfilled, (state, action) => {
        return { ...state, ...action.payload }
      })
  },
})


// Actions

export default lotteryDataSlice.reducer
