import React from "react";
import { Svg, SvgProps } from '@lucky-chip/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="18" height="18" rx="9" fill="#111111"/>
      <path d="M8.59998 2.56668L5.16665 6.00001C5.03332 6.13335 5.03332 6.34001 5.16665 6.47335L6.31999 7.62668C6.45332 7.76001 6.65999 7.76001 6.79332 7.62668L10.2266 4.19335C10.36 4.06001 10.36 3.85335 10.2266 3.72001L9.07331 2.56668C8.93997 2.44001 8.73331 2.44001 8.59998 2.56668Z" fill="#FFD748"/>
      <path d="M11.2 5.17333L5.16665 11.2067C5.03332 11.34 5.03332 11.5467 5.16665 11.68L6.31999 12.8333C6.45332 12.9667 6.65999 12.9667 6.79332 12.8333L12.8266 6.8C12.96 6.66667 12.96 6.46 12.8266 6.32667L11.6733 5.17333C11.5467 5.04 11.3333 5.04 11.2 5.17333Z" fill="#FFD748"/>
      <path d="M13.8067 7.77334L7.77334 13.8067C7.64001 13.94 7.64001 14.1467 7.77334 14.28L8.92668 15.4333C9.06001 15.5667 9.26667 15.5667 9.40001 15.4333L15.4333 9.40001C15.5667 9.26667 15.5667 9.06001 15.4333 8.92667L14.28 7.77334C14.1467 7.64001 13.9333 7.64001 13.8067 7.77334Z" fill="#FFD748"/>
      <path d="M3.72667 10.2267L2.57333 9.07335C2.43999 8.94001 2.43999 8.73335 2.57333 8.60001L3.72667 7.44668C3.86 7.31335 4.06666 7.31335 4.2 7.44668L5.35334 8.60001C5.48667 8.73335 5.48667 8.94001 5.35334 9.07335L4.2 10.2267C4.06666 10.36 3.85334 10.36 3.72667 10.2267Z" fill="#FFD748"/>
    </Svg>

  );
};

export default Icon;
