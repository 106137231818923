import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { WhiteListState } from 'state/types';
import { getWhiteListData } from './helpers';

const initialState: WhiteListState = {
  bnbBalance: null,
  lcPrice: null,
  maxAmount: null,
  minAmount: null,
  pendingLC: null,
  isWhitelisted: false,
}

// Thunks
export const loadWhiteList = createAsyncThunk<WhiteListState, string>('whitelist/load', getWhiteListData)

export const whitelistSlice = createSlice({
  name: 'whitelist',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder.addCase(loadWhiteList.pending, (state, action) => {
     return {
       ...state,
       bnbBalance: null,
       lcPrice: null,
       maxAmount: null,
       minAmount: null,
       pendingLC: null,
       isWhitelisted: false,
     }
    })
    builder.addCase(loadWhiteList.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
  },
})


// Actions

export default whitelistSlice.reducer
