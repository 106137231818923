import React, { useEffect, useMemo, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { Box, Button, Flex, Image, Text, useMatchBreakpoints } from '@lucky-chip/uikit';
import { useAirdrop } from '../../../state/airdrop/hooks';
import { useAirdropNotifState } from '../../../state/airdrop-notif/hooks';
import { setAnnonAirdropNotifShown, setUserAirdropNotifShown } from '../../../state/airdrop-notif';
import { useAppDispatch } from '../../../state';

const TIMEOUT = 3000

const AirdropNotifModal = () => {
  const { main, player } = useAirdrop()
  const { account } = useWeb3React()
  const { isMobile } = useMatchBreakpoints()
  const [show, setShow] = useState(false)
  const [timedout, setTimedout] = useState(false)
  const [message, setMessage] = useState(<>You just win a<br/>Free Lucky Airdrop.</>)
  const airdrop = useAirdrop()
  const airdropNotif = useAirdropNotifState()
  const totalAmount = useMemo(() => {
    const mainTotal = airdrop.main.total
    const playerTotal = airdrop.player.total
    return mainTotal || playerTotal || null
  }, [airdrop])
  
  const hasPlayedAll = useMemo(() => {
    const mainTotal = airdrop.main.total
    const playerTotal = airdrop.player.total
    if (mainTotal !== null && playerTotal !== null) {
      if (mainTotal !== 0) {
        return airdrop.main.flipPlayed && airdrop.main.dicePlayed
      }
      return airdrop.player.flipPlayed && airdrop.player.dicePlayed
    }
    return null
  }, [airdrop])

  const dispatch = useAppDispatch()
  useEffect(() => {
    setTimeout(() => {
      setTimedout(() => true)
    }, TIMEOUT)
  }, [])
  useEffect(() => {
    if (timedout) {
      if (account && hasPlayedAll === false && !airdropNotif.user) {
        setMessage(<>You just win a<br/>Lucky Airdrop of {totalAmount}LC</>)
        if (!airdropNotif.user) {
          setShow(true)
          dispatch(setUserAirdropNotifShown())
        }
      } else if (!account && !airdropNotif.annon) {
          setShow(true)
          dispatch(setAnnonAirdropNotifShown())
        }
    }
  }, [timedout, account, airdropNotif.annon, airdropNotif.user, dispatch, airdrop.player.flipPlayed, airdrop.player.dicePlayed, hasPlayedAll, totalAmount])

  return show ? <Box position="fixed" width="100vw" height="100vh" zIndex={20} px={isMobile ? 10 : 0}>
    <Box width="100%" height="100%" background="#452a7a" style={{ opacity: 0.6 }} position="absolute" left={0} top={0} onClick={() => setShow(false)}/>
    <Flex justifyContent="center" alignItems="center" width="100%" height="100%" left={0} top={0}>
      <Box border="1px solid #B3A527" borderRadius="20px" zIndex={10} background="center / cover url(/images/bg-desktop.jpg) !important" position="relative" pb={150} overflow="hidden" maxWidth="100%">
        <Flex justifyContent="flex-end" mt={20} px={20}>
          <Flex justifyContent="center" alignItems="center" width="20px" height="20px" onClick={() => setShow(false)} style={{ cursor: 'pointer', background: 'white', borderRadius: '20px', outline: 'none', border: 'none' }}>x</Flex>
        </Flex>
        <Flex mt={15} flexDirection="column" position="relative" px={isMobile ? 20 : 40}>
          <Text color="gold" fontSize={isMobile ? "32px" : "40px"} bold textAlign="center">Congratulations!</Text>
          <Text color="white" fontSize={isMobile ? "24px" : "28px"} bold textAlign="center">{message}</Text>
          <Button variant="secondary" onClick={() => setShow(false)} scale="md" mt={40}>{account ? 'Go Claim!' : 'Connect your wallet and check!'}</Button>
        </Flex>
        <Image
          width={440}
          height={282}
          src="/images/casino.png"
          alt="casino"
          style={{
            position: 'absolute',
            bottom: isMobile ? '-100px' : '',
            transform: 'rotate(-9.62deg)',
            mixBlendMode: 'lighten',
          }}
        />
        <Image width={220} height={220} src="/images/stackchip.png" alt="stackchip" style={{ position: 'absolute', bottom: '-120px', left: 0 }}/>
        <Image width={220} height={220} src="/images/stackchip.png" alt="stackchip" style={{ position: 'absolute', bottom: '-130px', right: 0 }}/>
        <Image width={220} height={220} src="/images/stackchip.png" alt="stackchip" style={{ position: 'absolute', bottom: '-80px', left: '70px' }}/>
      </Box>
    </Flex>
  </Box> : <></>
}

export default AirdropNotifModal