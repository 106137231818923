import { useEffect } from 'react';
import { useAppDispatch } from 'state';
import useActiveWeb3React from '../../../hooks/useActiveWeb3React';
import { fetchBetMiningTables } from '../../../state/bet-mining';

const usePollBetMiningData = () => {
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()

  useEffect(() => {
    dispatch(fetchBetMiningTables(account))
  }, [account, dispatch])
}

export default usePollBetMiningData
