import { createSlice } from '@reduxjs/toolkit';
import { AirdropNotifState } from 'state/types';

const initialState: AirdropNotifState = {
  annon: false,
  user: false,
}

export const airdropNotifSlice = createSlice({
  name: 'airdrop',
  initialState,
  reducers: {
    setAnnonAirdropNotifShown: (state) => {
      state.annon = true
    },
    setUserAirdropNotifShown: (state) => {
      state.user = true
    }
  },
})


// Actions
export const { setAnnonAirdropNotifShown, setUserAirdropNotifShown } = airdropNotifSlice.actions
export default airdropNotifSlice.reducer
