/**
 * overwrite arr2 content over arr1 by a member with `key` and returns the array, and sort by `key`
 * @param arr1
 * @param arr2
 * @param key
 */
export function mergeArrays<T>(arr1: Array<T>, arr2: Array<T>, key = 'id'): Array<T> {
  const result = arr1.filter(m => !arr2.find(p => p[key] === m[key]))
  result.push(...arr2)
  result.sort((a, b) => a[key] > b[key] ? 1 : -1)
  return result
}
