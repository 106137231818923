import React from "react";
import { Svg, SvgProps } from '@lucky-chip/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="18" height="18" rx="9" fill="#A62A42"/>
      <path d="M3.26931 7.6464C2.66632 7.84239 2.53747 8.00257 2.41903 8.11805C4.55091 7.7716 5.97217 9.8503 9.30964 12.4967C10.6349 13.5475 13.5734 14.229 14.9947 12.4967C16.98 10.077 15.7053 7.99285 13.9287 6.2606C12.1522 4.52835 10.7309 4.8748 9.66496 5.5677C8.59902 6.2606 7.88839 6.9535 6.46713 7.29995C5.04588 7.6464 4.33525 7.29995 3.26931 7.6464Z" fill="white"/>
      <path d="M2.91399 10.418C4.051 11.2495 5.73529 11.1206 6.32748 10.8897C4.76659 8.93616 2.77435 8.4645 2.41904 8.4645C2.06372 8.4645 1.49274 9.37865 2.91399 10.418Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
