import BigNumber from 'bignumber.js';
import erc20ABI from 'config/abi/erc20.json';
import masterchefABI from 'config/abi/masterchef.json';
import multicall from 'utils/multicall';
import { getAddress, getMasterChefAddress } from 'utils/addressHelpers';
import { SerializedFarmConfig } from 'config/constants/types';

export const fetchFarmUserAllowances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()

  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return { address: lpContractAddress, name: 'allowance', params: [account, masterChefAddress] }
  })

  const rawLpAllowances = await multicall(erc20ABI, calls)
  const parsedLpAllowances = rawLpAllowances.map((lpBalance) => {
    return new BigNumber(lpBalance).toJSON()
  })
  return parsedLpAllowances
}

export const fetchFarmUserTokenBalances = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const calls = farmsToFetch.map((farm) => {
    const lpContractAddress = getAddress(farm.lpAddresses)
    return {
      address: lpContractAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicall(erc20ABI, calls)
  const parsedTokenBalances = rawTokenBalances.map((tokenBalance) => {
    return new BigNumber(tokenBalance).toJSON()
  })
  return parsedTokenBalances
}

export const fetchFarmUserStakedBalancesAndWithdrawTimestamp = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'userInfo',
      params: [farm.pid, account],
    }
  })

  const callResults = await multicall(masterchefABI, calls)
  const result = callResults.map((stakedBalance) => {
    return {
      stakedBalance: new BigNumber(stakedBalance[0]._hex).toJSON(),
      nextWithdrawUntil: new BigNumber(stakedBalance[3]._hex).toNumber(),
    }
  })
  return result
}

export const fetchFarmUserClaimRatio = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'getClaimRatio',
      params: [farm.pid, account],
    }
  })

  const rawClaimRatios = await multicall(masterchefABI, calls)
  const parsedClaimRatios = rawClaimRatios.map((claimRatio) => {
    return new BigNumber(claimRatio[0]._hex).toNumber()
  })
  return parsedClaimRatios
}

export const fetchFarmUserEarnings = async (account: string, farmsToFetch: SerializedFarmConfig[]) => {
  const masterChefAddress = getMasterChefAddress()

  const calls = farmsToFetch.map((farm) => {
    return {
      address: masterChefAddress,
      name: 'pendingLC',
      params: [farm.pid, account],
    }
  })

  const rawEarnings = await multicall(masterchefABI, calls)
  const parsedEarnings = rawEarnings.map((earnings) => {
    return new BigNumber(earnings).toJSON()
  })
  return parsedEarnings
}
