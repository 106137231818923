import { multicallv2 } from 'utils/multicall';
import { getBigNumberFromEthers } from 'utils/formatBalance';
import luckyPowerAbi from 'config/abi/lucky-power.json';
import { getLuckyPowerAddress } from 'utils/addressHelpers';
import { HomeState } from '../types';
import { getBetMiningContract, getMasterchefContract, getReferralContract } from '../../utils/contractHelpers';

export const getLuckyPowerAndBonus = async (account: string): Promise<HomeState> => {
  if (!account) {
    return {
      luckyPower: null,
      luckyBonuses: {},
      bettingVolume: null,
      bankerTVL: null,
      referralsEarning: null,
      farmTVL: null,
      playBonus: null,
      bankerBonus: null,
      referralsBonus: null,
      farmBonus: null,
    }
  }
  const address = getLuckyPowerAddress()
  const calls = [
    {
      address,
      name: 'getPower',
      params: [account]
    },
    {
      address,
      name: 'pendingRewards',
      params: [account]
    },
    {
      address,
      name: 'withdrawPlayerBonus',
      params: []
    },
    {
      address,
      name: 'withdrawBankerBonus',
      params: []
    },
    {
      address,
      name: 'withdrawReferrerBonus',
      params: []
    },
    {
      address,
      name: 'withdrawLpBonus',
      params: []
    },
  ]
  const callResults = await multicallv2(luckyPowerAbi, calls)
  const rawLuckyPower = callResults[0]
  const rawLuckyBonuses = callResults[1]
  const [tokenAddresses] = rawLuckyBonuses
  const tokenAmounts = rawLuckyBonuses[1]
  const luckyPower = getBigNumberFromEthers(rawLuckyPower[0])
  const luckyBonuses = {}
  tokenAddresses.forEach((tokenAddress, index) => {
    const amount = getBigNumberFromEthers(tokenAmounts[index])
    luckyBonuses[tokenAddress] = amount.toJSON()
  })

  const [playBonus] = callResults[2]
  const [bankerBonus] = callResults[3]
  const [referralsBonus] = callResults[4]
  const [farmBonus] = callResults[5]

  const betMiningContract = getBetMiningContract()
  const referralsContract = getReferralContract()
  const masterChefContract = getMasterchefContract()
  const bettingVolume = await betMiningContract.getPoolQuantityUSDT()
  const referrralEarnings = await referralsContract.accCommission()
  const farmTVL = await masterChefContract.getTvlBusd()

  return {
    luckyPower: luckyPower.toJSON(),
    luckyBonuses,
    bettingVolume: getBigNumberFromEthers(bettingVolume).toJSON(),
    bankerTVL: null,
    referralsEarning: getBigNumberFromEthers(referrralEarnings).toJSON(),
    farmTVL: getBigNumberFromEthers(farmTVL).toJSON(),
    playBonus: getBigNumberFromEthers(playBonus).toJSON(),
    bankerBonus: getBigNumberFromEthers(bankerBonus).toJSON(),
    referralsBonus: getBigNumberFromEthers(referralsBonus).toJSON(),
    farmBonus: getBigNumberFromEthers(farmBonus).toJSON(),
  }
}
