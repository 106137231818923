import { multicallv2 } from 'utils/multicall';
import diceReferralAbi from 'config/abi/bnb-dice-referral.json';
import { DiceReferralState } from '../types';
import { getBigNumberFromEthers } from '../../utils/formatBalance';
import { oldDiceTableIds } from '../../config/constants/betTableConfigs';
import { getDiceReferralAddress } from '../../utils/addressHelpers';

export const getDiceReferralsData = async (account: string): Promise<DiceReferralState> => {
  if (!account) {
    const result: DiceReferralState = {}
    oldDiceTableIds.forEach(diceId => {
      result[diceId] = {
        accCommission: null,
        pendingCommission: null,
        referralsCount: null,
      }
    })
    return result
  }
  const referralCalls = oldDiceTableIds.map(diceId => {
    const address = getDiceReferralAddress(diceId)
    return { address, name: 'getReferralCommission', params: [account] }
  })

  const multiCallResults = await multicallv2(diceReferralAbi, referralCalls)
  const result: DiceReferralState = {}
  oldDiceTableIds.forEach((diceId, index) => {
    const [accCommission, pendingCommission, referralsCount] = multiCallResults[index]
    result[diceId] = {
      accCommission: getBigNumberFromEthers(accCommission).toString(),
      pendingCommission: getBigNumberFromEthers(pendingCommission).toString(),
      referralsCount: referralsCount.toNumber(),
    }
  })

  return result
}