const ARCHIVED_FARMS_START_PID = 100
const ARCHIVED_FARMS_END_PID = 250

const EXPIRED_FARMS_START_PID = 0
const EXPIRED_FARMS_END_PID = 6

export const isArchivedPid = (pid: number) => pid >= ARCHIVED_FARMS_START_PID && pid <= ARCHIVED_FARMS_END_PID

export const isExpiredPid = (pid: number) => pid >= EXPIRED_FARMS_START_PID && pid <= EXPIRED_FARMS_END_PID

export default isArchivedPid
