import React from 'react';
import { Button, useMatchBreakpoints, useWalletModal } from '@lucky-chip/uikit';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'contexts/Localization';

const ConnectWalletButton = (props) => {
  const { t } = useTranslation()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout, t, 'center / cover url(/images/bg-desktop.jpg) !important')
  const { isXs, isSm } = useMatchBreakpoints()

  return (
    <Button variant="primary" onClick={onPresentConnectModal} {...props} px={(isXs || isSm) ? "15px" : ""}>
      {t('Connect Wallet')}
    </Button>
  )
}

export default ConnectWalletButton
