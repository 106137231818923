import { useEffect } from 'react';
import useRefresh from '../../../hooks/useRefresh';
import { useAppDispatch } from '../../../state';
import { fetchLotteryInfo } from '../../../state/lottery';
import useActiveWeb3React from '../../../hooks/useActiveWeb3React';

export const usePollLottery = () => {
  const { slowRefresh } = useRefresh()
  const dispatch = useAppDispatch()
  const { account } = useActiveWeb3React()

  useEffect(() => {
    dispatch(fetchLotteryInfo(account))
  }, [slowRefresh, dispatch, account])
}