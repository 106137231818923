import { multicallv2 } from 'utils/multicall';
import { getBetMiningAddress } from 'utils/addressHelpers';
import { BetMiningDice } from '../types';
import { getBigNumberFromEthers } from '../../utils/formatBalance';
import { betMiningPoolTableIds } from '../../config/constants/betTableConfigs';
import betMiningAbi from '../../config/abi/bet-mining.json';

export const getBetMiningTables = async (account: string): Promise<{ [key: number]: BetMiningDice }> => {
  const betMiningCalls = ['poolInfo', 'userInfo', 'pendingRewards']
  const publicCallCount = 1
  const userCallCount = 2
  if (!account) {
    // do not include user calls when the account is not connected
    betMiningCalls.splice(publicCallCount, userCallCount)
  }
  const address = getBetMiningAddress()

  const staticCalls = betMiningPoolTableIds.reduce((acc, _, pid) => {
    const calls = betMiningCalls.map((method, index) => {
      const params = index >= publicCallCount ? [pid, account] : [pid]
      return { address, name: method, params }
    })
    return [...acc, ...calls]
  }, [])
  const dicesMultiCallResults = await multicallv2(betMiningAbi, staticCalls)

  return betMiningPoolTableIds.reduce((acc, diceId, index) => {
    const startIndex = index * betMiningCalls.length
    const { allocPoint, quantity: currentMiningVolume } = dicesMultiCallResults[startIndex]
    const userCallStartIndex = publicCallCount
    const { quantity: myBetMiningVolume, accRewardAmount } = account ? dicesMultiCallResults[startIndex + userCallStartIndex] : { quantity: null, accRewardAmount: null }
    const [myLCMined] = account ? dicesMultiCallResults[startIndex + userCallStartIndex + 1] : [null]
    const miningRate = allocPoint / 100
    const config = {
      diceId,
      APR: null,
      miningRate,
      currentMiningVolume: getBigNumberFromEthers(currentMiningVolume).toJSON(),
      myBetMiningVolume: myBetMiningVolume !== null ? getBigNumberFromEthers(myBetMiningVolume).toJSON() : null,
      totalLCMined: accRewardAmount !== null ? getBigNumberFromEthers(accRewardAmount).toJSON() : null,
      myLCMined: myLCMined !== null ? getBigNumberFromEthers(myLCMined).toJSON() : null,
    }
    return { ...acc, [diceId]: config }
  }, {})
}
