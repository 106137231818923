import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BetMiningDice, BetMiningState } from 'state/types';
import { getBetMiningTables } from './helpers';

const initialState: BetMiningState = {
  tables: {},
}

// Thunks
export const fetchBetMiningTables = createAsyncThunk<{ [key: number]: BetMiningDice }, string>('betMining/fetchTables', getBetMiningTables)

export const betMiningDataSlice = createSlice({
  name: 'betMining',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchBetMiningTables.pending, (state => {
        return { ...state, tables: {} }
      }))

    builder
      .addCase(fetchBetMiningTables.fulfilled, (state, action) => {
        return { ...state, tables: action.payload }
      })
  },
})


// Actions

export default betMiningDataSlice.reducer
