import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BlockState } from '../types';

const initialState: BlockState = { currentBlock: 0, initialBlock: 0, timestamp: 0 }

export const blockSlice = createSlice({
  name: 'Block',
  initialState,
  reducers: {
    setBlock: (state, action: PayloadAction<{ block: number, timestamp: number }>) => {
      const { block, timestamp } = action.payload
      if (state.initialBlock === 0) {
        state.initialBlock = block
      }

      state.currentBlock = block
      state.timestamp = timestamp
    },
  },
})

// Actions
export const { setBlock } = blockSlice.actions

export default blockSlice.reducer
