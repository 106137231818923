import { ChainId, Token } from '@luckychip/luckychip-sdk';
import { serializeToken } from 'state/user/hooks/helpers';
import { SerializedToken } from './types';

const { MAINNET, TESTNET } = ChainId

export const rewardTokenSymbols = ['wbnb', 'lc', 'usdt']

interface TokenList {
  [symbol: string]: Token
}

interface SerializedTokenList {
  [symbol: string]: SerializedToken
}
// TODO: CONTRACT UPDATE
export const mainnetTokens = {
  wbnb: new Token(
    MAINNET,
    '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new Token(MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'BNB', 'BNB', 'https://www.binance.com/'),
  lc: new Token(
    MAINNET,
    '0x6012C3a742f92103d238F1c8306cF8fbcDEca8B3',
    18,
    'LC',
    'LuckyChip Token',
    'https://luckychip.io/',
  ),
  busd: new Token(
    MAINNET,
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  btcb: new Token(
    MAINNET,
    '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c',
    18,
    'BTCB',
    'Binance BTC',
    'https://bitcoin.org/',
  ),
  eth: new Token(
    MAINNET,
    '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
    18,
    'ETH',
    'ETH',
    'https://bitcoin.org/',
  ),
  cake: new Token(
    MAINNET,
    '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82',
    18,
    'CAKE',
    'CAKE',
    'https://Luckychip.io/',
  ),
  banana: new Token(
    MAINNET,
    '0x603c7f932ED1fc6575303D8Fb018fDCBb0f39a95',
    18,
    'BANANA',
    'Banana',
    'https://Luckychip.io/',
  ),
  baby: new Token(
    MAINNET,
    '0x53e562b9b7e5e94b81f10e96ee70ad06df3d2657',
    18,
    'BABY',
    'BabySwap Token',
    'https://Luckychip.io/',
  ),
  usdt: new Token(
    MAINNET,
    '0x55d398326f99059fF775485246999027B3197955',
    18,
    'USDT',
    'Tether USD',
    'https://Luckychip.io/',
  ),
  lcdice: new Token(
    MAINNET,
    '0xB48859f5F9154Be1d9b239b43471Ef665b30d436',
    18,
    'LuckyChipDice',
    'LuckyChipDice',
    'https://Luckychip.io/',
  ),
  lcflip: new Token(
    MAINNET,
    '0xB48859f5F9154Be1d9b239b43471Ef665b30d436',
    18,
    'LuckyChipDice',
    'LuckyChipDice',
    'https://Luckychip.io/',
  ),
  bnbdice: new Token(
    MAINNET,
    '0xe26f357E9D4cff2d01bd1d4a7B55Fb77B86b6F88',
    18,
    'LuckyBNB',
    'LuckyBNB',
    'https://luckychip.io/',
  ),
  bnbflip: new Token(
    MAINNET,
    '0x79668624c329F605735A10525C12f6EDdC14aDd8',
    18,
    'LuckyBNB',
    'LuckyBNB',
    'https://luckychip.io/',
  ),
  busddice: new Token(
    MAINNET,
    '0xAa6879E6912eDcE431E3B4f64D027963e59FAAB9',
    18,
    'LuckyBUSD',
    'LuckyBUSD',
    'https://luckychip.io/',
  ),
  lcgame: new Token(
    MAINNET,
    '0x0D9E2DE2B5f0BBEec9c8A093e43d087Ee7C080a1',
    18,
    'LCGameToken',
    'LCGameToken',
    'https://Luckychip.io/',
  ),
  bnbgame: new Token(
    MAINNET,
    '0xAF1Ae177f5579d668d317d9706845e87c23B46BA',
    18,
    'LCBNBGameToken',
    'LCBNBGameToken',
    'https://Luckychip.io/',
  ),
  btcbdice: new Token(
    MAINNET,
    '0x458be0422F3D2D12F7d78BAd1ca208Bffb1E9Fa1',
    18,
    'LuckyBTCB',
    'LuckyBTCB',
    'https://luckychip.io/',
  ),
  ethdice: new Token(
    MAINNET,
    '0x30f61B0B37708ab60A0a7E16a9457F8fc1CFAC51',
    18,
    'LuckyETH',
    'LuckyETH',
    'https://luckychip.io/',
  ),
  usdtdice: new Token(
    MAINNET,
    '0xa247B0b8c8baeb974aE0C64Ad4504b30AB4a8aa4',
    18,
    'LuckyUSDT',
    'LuckyUSDT',
    'https://luckychip.io/',
  ),
  usdtflip: new Token(
    MAINNET,
    '0xa247B0b8c8baeb974aE0C64Ad4504b30AB4a8aa4',
    18,
    'LuckyUSDT',
    'LuckyUSDT',
    'https://luckychip.io/',
  ),
}

export const testnetTokens = {
  wbnb: new Token(
    TESTNET,
    '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    18,
    'WBNB',
    'Wrapped BNB',
    'https://www.binance.com/',
  ),
  // bnb here points to the wbnb contract. Wherever the currency BNB is required, conditional checks for the symbol 'BNB' can be used
  bnb: new Token(
    TESTNET,
    '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
    18,
    'BNB',
    'BNB',
    'https://www.binance.com/',
  ),
  banana: new Token(
    TESTNET,
    '0x4Fb99590cA95fc3255D9fA66a1cA46c43C34b09a',
    18,
    'BANANA',
    'Banana',
    'https://Luckychip.io/',
  ),
  lc: new Token(
    TESTNET,
    '0x2309E26377A840B8ee7eAE87E1f7DBeA1344a086',
    18,
    'LC',
    'LuckyChip Token',
    'https://luckychip.io/',
  ),
  busd: new Token(
    TESTNET,
    '0xeD24FC36d5Ee211Ea25A80239Fb8C4Cfd80f12Ee',
    18,
    'BUSD',
    'Binance USD',
    'https://www.paxos.com/busd/',
  ),
  btcb: new Token(
    TESTNET,
    '0x6ce8dA28E2f864420840cF74474eFf5fD80E65B8',
    18,
    'BTCB',
    'Binance BTC',
    'https://bitcoin.org/',
  ),
  eth: new Token(
    TESTNET,
    '0xd66c6B4F0be8CE5b39D52E0Fd1344c389929B378',
    18,
    'ETH',
    'ETH',
    'https://bitcoin.org/',
  ),
  usdt: new Token(
    TESTNET,
    '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
    18,
    'USDT',
    'Tether USD',
    'https://luckychip.io/',
  ),
  lcdice: new Token(
    TESTNET,
    '0xaC8c01c837B2E42dAcd62E7cEf6Df4F194cc017c',
    18,
    'LuckyChipDice',
    'LuckyChipDice',
    'https://luckychip.io/',
  ),
  lcflip: new Token(
    TESTNET,
    '0x90e84FdDAb4e8D89528B59f8F81575634d64F66d',
    18,
    'LuckyChipDice',
    'LuckyChipDice',
    'https://luckychip.io/',
  ),
  bnbdice: new Token(
    TESTNET,
    '0x316947C9f32CDeF1E277B423F6ad6B5bbE0FaF83',
    18,
    'LuckyBNB',
    'LuckyBNB',
    'https://luckychip.io/',
  ),
  bnbflip: new Token(
    TESTNET,
    '0xd9Ab2A37ffB8001ed91c61D75FccC76Eec412b81',
    18,
    'LuckyBNB',
    'LuckyBNB',
    'https://luckychip.io/',
  ),
  usdtdice: new Token(
    TESTNET,
    '0x3D008DDFC30e9A7A48C9777d668276d577991995',
    18,
    'LuckyUSDT',
    'LuckyUSDT',
    'https://luckychip.io/',
  ),
  usdtflip: new Token(
    TESTNET,
    '0xA80B42E81e46Ee8D2dC5460dbd7Ce5F93D1b74Dd',
    18,
    'LuckyUSDT',
    'LuckyUSDT',
    'https://luckychip.io/',
  ),
}

const tokens = (): TokenList => {
  const chainId = process.env.REACT_APP_CHAIN_ID

  // If testnet - return list comprised of testnetTokens wherever they exist, and mainnetTokens where they don't
  if (parseInt(chainId, 10) === ChainId.TESTNET) {
    return Object.keys(mainnetTokens).reduce((accum, key) => {
      return { ...accum, [key]: testnetTokens[key] || mainnetTokens[key] }
    }, {})
  }

  return mainnetTokens
}

export const serializeTokens = (): SerializedTokenList => {
  const unserializedTokens = tokens()
  const serializedTokens = Object.keys(unserializedTokens).reduce((accum, key) => {
    return { ...accum, [key]: serializeToken(unserializedTokens[key]) }
  }, {})

  return serializedTokens
}

export default tokens()
