import { serializeTokens } from './tokens';
import { FarmPid, FarmType, GameType, SerializedFarmConfig } from './types';

const serializedTokens = serializeTokens()

// TODO: CONTRACT UPDATE
// TODO: Add XXXDiceToken address for non-LP farm
const farms: SerializedFarmConfig[] = [
  {
    pid: FarmPid.LC_BNB_LP,
    name: 'LC-BNB Farm',
    lpSymbol: 'LC-BNB LP',
    lpAddresses: {
      97: '0xD00A817250c7D7250548E404279B9D4fFC17Ab64',
      56: '0xA7610F4Ad54778f2108A6634d3cb9f8ff61923DE',
    },
    token: serializedTokens.lc,
    quoteToken: serializedTokens.wbnb,
    type: FarmType.Liquidity,
    bankType: null,
  },
  {
    pid: FarmPid.LC_USDT,
    name: 'LC-USDT Farm',
    lpSymbol: 'LC-USDT LP',
    lpAddresses: {
      97: '0x576b547e2D96F0f040dCcE18D864846d91954b42',
      56: '0x6243b0365B5D43Aba1209fcaA7676fd688657738',
    },
    token: serializedTokens.lc,
    quoteToken: serializedTokens.usdt,
    type: FarmType.Liquidity,
    bankType: null,
  },
  {
    pid: FarmPid.LC_DICE_TOKEN,
    name: 'LC Dice Farm',
    lpSymbol: 'LuckyLC',
    lpAddresses: {
      97: '0xaC8c01c837B2E42dAcd62E7cEf6Df4F194cc017c',
      56: '0xB48859f5F9154Be1d9b239b43471Ef665b30d436',
    },
    token: serializedTokens.lcdice,
    quoteToken: serializedTokens.lcdice,
    type: FarmType.Bank,
    bankType: GameType.Dice,
  },
  {
    pid: FarmPid.BNB_DICE_TOKEN,
    name: 'BNB Dice Farm',
    lpSymbol: 'LuckyBNB',
    lpAddresses: {
      97: '0x316947C9f32CDeF1E277B423F6ad6B5bbE0FaF83',
      56: '0xe26f357E9D4cff2d01bd1d4a7B55Fb77B86b6F88',
    },
    token: serializedTokens.bnbdice,
    quoteToken: serializedTokens.bnbdice,
    type: FarmType.Bank,
    bankType: GameType.Dice,
  },
  {
    pid: FarmPid.USDT_DICE_TOKEN,
    name: 'USDT Dice Farm',
    lpSymbol: 'LuckyUSDT',
    lpAddresses: {
      97: '0x3D008DDFC30e9A7A48C9777d668276d577991995',
      56: '0xa247B0b8c8baeb974aE0C64Ad4504b30AB4a8aa4',
    },
    token: serializedTokens.usdtdice,
    quoteToken: serializedTokens.usdtdice,
    type: FarmType.Bank,
    bankType: GameType.Dice,
  },
  {
    pid: FarmPid.BNB_FLIP_TOKEN,
    name: 'BNB Flip Farm',
    lpSymbol: 'FlipBNB',
    lpAddresses: {
      97: '0xd9Ab2A37ffB8001ed91c61D75FccC76Eec412b81',
      56: '0x79668624c329F605735A10525C12f6EDdC14aDd8',
    },
    token: serializedTokens.bnbflip,
    quoteToken: serializedTokens.bnbflip,
    type: FarmType.Bank,
    bankType: GameType.Flip,
  },
  {
    pid: FarmPid.LC_GAME_TOKEN,
    name: 'LC Game Farm',
    lpSymbol: 'GameLC',
    lpAddresses: {
      97: '',
      56: '0x0D9E2DE2B5f0BBEec9c8A093e43d087Ee7C080a1',
    },
    token: serializedTokens.lcgame,
    quoteToken: serializedTokens.lcgame,
    type: FarmType.Bank,
    bankType: GameType.Game,
  },
  {
    pid: FarmPid.BNB_GAME_TOKEN,
    name: 'BNB Game Farm',
    lpSymbol: 'GameBNB',
    lpAddresses: {
      97: '',
      56: '0xAF1Ae177f5579d668d317d9706845e87c23B46BA',
    },
    token: serializedTokens.bnbgame,
    quoteToken: serializedTokens.bnbgame,
    type: FarmType.Bank,
    bankType: GameType.Game,
  },
]

export default farms
