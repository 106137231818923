import { LotteryCategory, LotteryInfo, LotteryState } from 'state/types';
import { multicallv2 } from 'utils/multicall';
import lotteryAbi from 'config/abi/lottery.json';
import { getLotteryAddress } from 'utils/addressHelpers';
import { getBigNumberFromEthers } from '../../utils/formatBalance';
import { DEFAULT_TOKEN_DECIMAL } from '../../config';

export const getLotteryInfo = async (account): Promise<LotteryState> => {
  const lotteryCallMethods = ['getAccPrize', 'getFirstPrize', 'getSecondPrize', 'getThirdPrize']
  const address = getLotteryAddress()
  const lotteryCalls = lotteryCallMethods.map(name => ({ address, name, params: [] }))

  if (account) {
    lotteryCalls.push({ address, name: 'lotteryInfo', params: [account] })
  }

  const callResults = await multicallv2(lotteryAbi, lotteryCalls)
  const totalLottery = callResults[0].map(v => getBigNumberFromEthers(v).toString())
  const latestLottery = {
    [LotteryCategory.Luckier]: getBigNumberFromEthers(callResults[1][2]).toString(),
    [LotteryCategory.Top]: getBigNumberFromEthers(callResults[2][2]).toString(),
    [LotteryCategory.Lucky]: getBigNumberFromEthers(callResults[3][2]).toString(),
  }
  let myLottery = {
    [LotteryCategory.Luckier]: null,
    [LotteryCategory.Top]: null,
    [LotteryCategory.Lucky]: null,
  }
  let myTotalLottery = null
  let accReward = null
  if (account) {
    const myLottery1 = getBigNumberFromEthers(callResults[4].firstPrizeAmount).toString()
    const myLottery2 = getBigNumberFromEthers(callResults[4].secondPrizeAmount).toString()
    const myLottery3 = getBigNumberFromEthers(callResults[4].thirdPrizeAmount).toString()
    myLottery = {
      [LotteryCategory.Luckier]: myLottery1,
      [LotteryCategory.Top]: myLottery2,
      [LotteryCategory.Lucky]: myLottery3,
    }
    myTotalLottery = getBigNumberFromEthers(callResults[4].pendingAmount).toString()
    accReward = getBigNumberFromEthers(callResults[4].accAmount).toString()
  }
  const luckierPlayers = callResults[1][0]
  const luckierPlayerCount = callResults[1][1].toNumber()
  const luckierPlayerRewards = getBigNumberFromEthers(callResults[1][2]).dividedBy(DEFAULT_TOKEN_DECIMAL).toNumber()

  const topPlayers = callResults[2][0]
  const topPlayerCount = callResults[2][1].toNumber()
  const topPlayerRewards = getBigNumberFromEthers(callResults[2][2]).dividedBy(DEFAULT_TOKEN_DECIMAL).toNumber()

  const luckyPlayers = callResults[3][0]
  const luckyPlayerCount = callResults[3][1].toNumber()
  const luckyPlayerRewards = getBigNumberFromEthers(callResults[3][2]).dividedBy(DEFAULT_TOKEN_DECIMAL).toNumber()

  const latestPlayers = {
    [LotteryCategory.Luckier]: luckierPlayers,
    [LotteryCategory.Top]: topPlayers,
    [LotteryCategory.Lucky]: luckyPlayers,
  }
  const categories = [LotteryCategory.Luckier, LotteryCategory.Top, LotteryCategory.Lucky]
  const lotteryInfos: { [key: string]: LotteryInfo } = {}
  totalLottery.forEach((t, i) => {
    const category = categories[i]
    lotteryInfos[category] = {
      totalLottery: t,
      latestLottery: latestLottery[category],
      myLottery: myLottery[category],
      latestPlayers: latestPlayers[category],
    }
  })
  return {
    lotteryInfos,
    myTotalLottery,
    luckierReward: luckierPlayerRewards / luckierPlayerCount,
    topReward: topPlayerRewards / topPlayerCount,
    luckyReward: luckyPlayerRewards / luckyPlayerCount,
    accReward,
  }
}
