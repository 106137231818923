import BigNumber from 'bignumber.js';
import { ApiState, AprMap, BetMiningApiData } from '../types';
import { getBetMiningDistributorContract } from '../../utils/contractHelpers';
import { DEFAULT_TOKEN_DECIMAL } from '../../config';

export const getFarmAPRs = (): Promise<AprMap> => {
  const apiUrl = process.env.REACT_APP_API_URL
  return fetch(`${apiUrl}/apr/all`)
    .then(data => data.json())
    .then(result => {
      return result.reduce((acc, item) => {
        const id = item.id.trim()
        const { value } = item
        const apr = Number((Number(value) * 100).toFixed(2))
        return { ...acc, [id]: apr }
      }, {})
    })
}

export const getBankAPRs = (): Promise<AprMap> => {
  const apiUrl = process.env.REACT_APP_API_URL
  return fetch(`${apiUrl}/apr/all`)
    .then(data => data.json())
    .then(result => {
      return result.reduce((acc, item) => {
        const id = item.id.trim()
        const { value } = item
        const apr = Number((Number(value) * 100).toFixed(2))
        return { ...acc, [id]: apr }
      }, {})
    })
}

type RankData = Pick<ApiState, 'quantityRank' | 'lpRank' | 'bankerRank' | 'playerRank' | 'referrerRank' | 'lpcommissionRank' | 'betcommissionRank' | 'activecntRank' | 'firstprizeRank' | 'secondprizeRank' | 'thirdprizeRank' | 'privateActiveCntRank' | 'betAmountRank'>
export const getRankData = (): Promise<RankData> => {
  const apiUrl = process.env.REACT_APP_API_URL
  return fetch(`${apiUrl}/rank/all`)
    .then(data => data.json())
    .then(result => {
      return result.reduce((acc, item) => {
        const id = item.id.trim()
        const { value } = item
        const parsed = JSON.parse(value)
        const list = Object.keys(parsed).map(address => ({ address, value: Number(parsed[address]) }))
        return { ...acc, [`${id}Rank`]: list }
      }, {})
    })
}

export const getBetMiningApiData = async (wallet: string): Promise<BetMiningApiData> => {
  const apiUrl = process.env.REACT_APP_API_URL
  const annonResult = await fetch(`${apiUrl}/apr/all`)
    .then(data => data.json())
  const dailyReward = annonResult.find(a => a.id === 'dailyRewardLC')
  const dailyBetVolume = annonResult.find(a => a.id === 'dailyBetVolume')
  const apr = annonResult.find(a => a.id === 'betMining')
  const userResult = wallet ? await fetch(`${apiUrl}/betmining/wallet/${wallet}`).then(data => data.json()) : { wallet: null, totalreward: null, volume: null, proof: null }
  const proof = wallet ? JSON.parse(userResult.proof || '[]') : []
  const betMiningDistributorContract = getBetMiningDistributorContract()
  const reward = new BigNumber(userResult.totalreward).multipliedBy(DEFAULT_TOKEN_DECIMAL).toString()
  const [b, pending] = wallet ? await betMiningDistributorContract.canClaim(wallet, reward, proof) : [false, null]
  return {
    dailyRewardLC: Number(dailyReward.value),
    dailyBetVolume: Number(dailyBetVolume.value),
    myTotalReward: wallet ? Number(userResult.totalreward) : null,
    myVolume: wallet ? Number(userResult.volume) : null,
    proof,
    pendingReward: pending !== null ? new BigNumber(pending._hex).dividedBy(DEFAULT_TOKEN_DECIMAL).toNumber() : null,
    apr: Math.round(Number(apr.value) * 100),
  }
}
