// TODO: CONTRACT UPDATE
export default {
  masterChef: {
    56: '0x15D2a6FC45aF66A2952dC27c40450C1F06A1eC2b',
    97: '0x0fc61c9Eca0edBC7A9f92289Dc84e6427BAa3f5a',
  },
  multiCall: {
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x5dAE2AA8338e4672C4c3E6Afba4891302bb90E22',
  },
  farmAuction: {
    56: '',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  wbnbDice: {
    56: '0xDF3E96a5d4A0C9AEf5F15C642Eb736E2A2f49630',
    97: '0x8BD4dF7cEb823E9887a0956B664E9e088394Df26', // BNBDice
  },
  wbnbDiceToken: {
    56: '0xe26f357E9D4cff2d01bd1d4a7B55Fb77B86b6F88',
    97: '0x316947C9f32CDeF1E277B423F6ad6B5bbE0FaF83', // BNBDiceToken
  },
  lcToken: {
    56: '0x6012C3a742f92103d238F1c8306cF8fbcDEca8B3',
    97: '0x2309E26377A840B8ee7eAE87E1f7DBeA1344a086', // LC
  },
  lcDice: {
    56: '0x42276d807749cECc25F9E8E25a2a09bb1E6B043f',
    97: '0x4D5708113f03f8adC0Da7f5390031494afCBdE0A', // LCDice
  },
  lcDiceToken: {
    56: '0xB48859f5F9154Be1d9b239b43471Ef665b30d436',
    97: '0xaC8c01c837B2E42dAcd62E7cEf6Df4F194cc017c', // LCDiceToken
  },
  usdtDice: {
    56: '0x682ce0e340A0248B4554E14e834969F2E421dB2D',
    97: '0x75a7402c5475888d590157e5B164E45ff72356ff',
  },
  usdtDiceToken: {
    56: '0xa247B0b8c8baeb974aE0C64Ad4504b30AB4a8aa4',
    97: '0x3D008DDFC30e9A7A48C9777d668276d577991995',
  },
  luckyPower: {
    56: '0x7353ba5E34b69c623AB552276C6EC89DA8332C5b',
    97: '0x604154A86f16f9aCC4be3957ACBd5B71106c9ABF',
  },
  referral: {
    56: '0xB91d7631d296422A88c9de7FC4f5233B965540a3',
    97: '0x775aC4f7f48a3b4F9F7dEC332fbEe0b94acf64d7',
  },
  bnbDiceReferral: {
    56: '0xc7E0E273B5aA7b5aE9466A299A5986010875e898',
    97: '0xD6e1aFb91D08B5dbCc15F23A51583e4649233e3F',
  },
  lcDiceReferral: {
    56: '0x40e2ABDeF03a4B1ad0F6a22e9bcc70B9E10470eD',
    97: '0x947AAD4D89df1bd872852568de33D1fD1671c9A1',
  },
  usdtDiceReferral: {
    56: '0x70d9917DB0d7e760a76D6bf57E886503aa89B385',
    97: '0xc1fffe48D9A4829a4B5cA3496C82551f18EdDea6',
  },
  oracle: {
    56: '0x785b997409b2d2F16179013acb85D8E6071f7320',
    97: '0xa5f7Acb15Ae8e2650C4E73Ae4676E1DFCBfCDc57',
  },
  oracleUsd: {
    56: '0x785b997409b2d2F16179013acb85D8E6071f7320',
    97: '0xa5f7Acb15Ae8e2650C4E73Ae4676E1DFCBfCDc57',
  },
  betMining: {
    56: '0xDB4a019aa054B0c04cB7d1E25E59D6fE2C966CC0',
    97: '0xca2898b27fc51c3c02b8a4Db6FC4F4872De2a90d',
  },
  lottery: {
    56: '0xF2289CcB00fcd07CdC2B4668a40953aac3B58E55', // SeasonRewardLC
    97: '0x12Ff40e1eE13eB47FE81F9a2D27945D8c01c3fE3',
  },
  mainAirdrop: {
    56: '0xaAa933173D848ff5FE895B999fca061DE7b7Ef2A',
    97: '',
  },
  playerAirdrop: {
    56: '0x70959c614C11f1a74c211909160EB1872E02aa8c',
    97: '',
  },
  apeswapAirdrop: {
    56: '0xB8f30369Fd70564eDD8B440Bf75D93E62c7bc6BD',
    97: '',
  },
  apeswapAmaAirdrop: {
    56: '0x6f8AC26a7A735c703612B59C13aD464C9E07E642',
    97: '',
  },
  babyAmaAirdrop: {
    56: '0x6e46620127a600D3c36AE947C661Cb2e5d21D10c',
    97: '',
  },
  zbcAmaAirdrop: {
    56: '0xFDAe980eb35223ad51e2D736865eE6B81de4e118',
    97: '',
  },
  whitelist: {
    56: '0x895C246a324A2c09217500352a409137ed5a0c3C',
    97: '',
  },
  bnbFlip: {
    56: '0x1934438465f3f312f913250336E4Ff826b6a4E7A',
    97: '0x55a2441C3077cDd8e8800e109088C0A507450b6B',
  },
  bnbFlipToken: {
    56: '0x79668624c329F605735A10525C12f6EDdC14aDd8',
    97: '0xd9Ab2A37ffB8001ed91c61D75FccC76Eec412b81',
  },
  lcGame: {
    56: '0x7297C2469652D7B0d56B85Cab86534aB3bE7a249',
    97: '0x57f886405DbF9e774215f2d1646291eA2264FD7D',
  },
  lcGameToken: {
    56: '0x0D9E2DE2B5f0BBEec9c8A093e43d087Ee7C080a1',
    97: '0x90e84FdDAb4e8D89528B59f8F81575634d64F66d',
  },
  bnbGame: {
    56: '0x45218EDE6f026F0994C55b6Fa3554A8Ea989f819',
    97: '0x55a2441C3077cDd8e8800e109088C0A507450b6B',
  },
  bnbGameToken: {
    56: '0xAF1Ae177f5579d668d317d9706845e87c23B46BA',
    97: '0xd9Ab2A37ffB8001ed91c61D75FccC76Eec412b81',
  },
  betMiningDistributor: {
    56: '0x767E0c15cD07Dd8D168dCdf6e1156EC83C9bd955',
    97: '',
  },
}
