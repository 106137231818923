import React from 'react';
import { Box, Flex, Link, Text } from '@lucky-chip/uikit';

export function ComingSoonGeneral() {
  return <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
    <Text fontSize="32px" color="gold" bold>Coming Soon</Text>
    <Box mt={10}>
      <Link href="/docs" style={{ color: 'white' }}>Check Docs for details</Link>
    </Box>
  </Flex>
}

export function ComingSoonAirdrop() {
  return <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
    <Text fontSize="32px" color="gold" bold>Coming Soon</Text>
    <Text fontSize="22px" color="white"><b>Lucky Player Airdrop</b>: LC airdrop to lucky players according to bet volume during Lucky Period</Text>
  </Flex>
}

export function ComingSoonBetMining() {
  return <Flex flexDirection="column" justifyContent="center" alignItems="center" height="100vh">
    <Text fontSize="32px" color="gold" bold>Coming Soon</Text>
    <Text fontSize="22px" color="white"><b>Lucky Season</b>: LC airdrop to lucky players according to bet volume</Text>
    <Text fontSize="22px" color="white"><b>Official Launch</b>: LC Farming for lucky players according to bet volume</Text>
    <Box mt={30}>
      <Link href="/docs" style={{ color: 'white' }}>Check Docs for details</Link>
    </Box>
  </Flex>
}