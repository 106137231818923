import React from "react";
import { SvgProps } from '@lucky-chip/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <svg width="34" height="33" viewBox="0 0 344 332" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g filter="url(#filter0_d)">
        <path fillRule="evenodd" clipRule="evenodd" d="M191.456 247.584C174.391 277.142 136.596 287.269 107.039 270.204C77.4813 253.139 67.3542 215.344 84.4191 185.787C101.484 156.229 139.279 146.102 168.836 163.167C198.393 180.232 208.52 218.027 191.456 247.584ZM113.218 259.5C136.864 273.152 167.1 265.05 180.752 241.405C194.404 217.759 186.302 187.523 162.656 173.871C139.01 160.219 108.775 168.321 95.1228 191.967C81.4708 215.612 89.5725 245.848 113.218 259.5Z" fill="#482402"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M171.116 235.836C160.536 254.161 137.103 260.44 118.778 249.86C100.452 239.279 94.1737 215.847 104.754 197.521C115.334 179.196 138.767 172.917 157.092 183.497C175.418 194.077 181.697 217.51 171.116 235.836ZM124.958 239.156C137.372 246.323 153.246 242.07 160.413 229.656C167.58 217.242 163.327 201.368 150.913 194.201C138.499 187.033 122.625 191.287 115.458 203.701C108.29 216.115 112.544 231.989 124.958 239.156Z" fill="#482402"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M154.849 187.391C138.67 178.05 117.983 183.593 108.642 199.772C99.3009 215.951 104.844 236.638 121.023 245.979C137.202 255.32 157.889 249.777 167.23 233.598C176.571 217.419 171.028 196.732 154.849 187.391ZM95.1213 191.966C108.773 168.32 139.009 160.218 162.655 173.87C186.301 187.522 194.402 217.758 180.75 241.404C167.099 265.05 136.863 273.151 113.217 259.5C89.5711 245.848 81.4694 215.612 95.1213 191.966Z" fill="#F6E6D2"/>
        <path d="M239.775 114.444C235.763 121.392 229.842 126.551 223.057 129.64C217.801 132.033 214.56 140.854 218.037 145.465C231.312 163.074 237.383 185.222 234.968 207.12C234.587 210.579 236.916 213.798 240.17 215.033C241.706 215.616 243.215 216.33 244.683 217.178C259.462 225.71 264.525 244.608 255.993 259.386C247.461 274.165 228.563 279.229 213.785 270.696L74.102 190.05C59.3233 181.518 54.2598 162.621 62.7922 147.842C71.3247 133.063 90.2221 128 105.001 136.532C106.469 137.38 107.842 138.33 109.115 139.368C111.812 141.569 115.763 141.977 118.569 139.916C136.325 126.876 158.542 121.061 180.43 123.752C186.161 124.457 192.18 117.24 191.624 111.493C190.906 104.072 192.414 96.3643 196.425 89.4155C206.664 71.6812 223.161 76.3086 240.896 86.5475C240.896 86.5475 250.014 96.7092 239.775 114.444Z" fill="#E89B37"/>
        <path d="M239.775 114.444C235.763 121.392 229.842 126.551 223.057 129.64C217.801 132.033 214.56 140.854 218.037 145.465C231.312 163.074 237.383 185.222 234.968 207.12C234.587 210.579 236.916 213.798 240.17 215.033C241.706 215.616 243.215 216.33 244.683 217.178C259.462 225.71 264.525 244.608 255.993 259.386C247.461 274.165 228.563 279.229 213.785 270.696L74.102 190.05C59.3233 181.518 54.2598 162.621 62.7922 147.842C71.3247 133.063 90.2221 128 105.001 136.532C106.469 137.38 107.842 138.33 109.115 139.368C111.812 141.569 115.763 141.977 118.569 139.916C136.325 126.876 158.542 121.061 180.43 123.752C186.161 124.457 192.18 117.24 191.624 111.493C190.906 104.072 192.414 96.3643 196.425 89.4155C206.664 71.6812 223.161 76.3086 240.896 86.5475C240.896 86.5475 250.014 96.7092 239.775 114.444Z" fill="url(#paint0_linear)"/>
        <path d="M225.871 201.872C228.286 179.975 222.214 157.827 208.939 140.217L205.56 146.071C210.668 170.427 206.518 170.886 204.464 189.512C204.083 192.972 206.412 196.19 209.666 197.426C211.201 198.009 212.71 198.723 214.179 199.571C228.958 208.103 234.021 227 225.489 241.779C216.956 256.558 198.059 261.621 183.28 253.089L204.687 265.448C219.466 273.981 238.363 268.917 246.896 254.138C255.428 239.36 250.365 220.462 235.586 211.93C234.118 211.082 232.609 210.369 231.073 209.785C227.819 208.55 225.49 205.332 225.871 201.872Z" fill="#E28432"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M217.937 118.396C222.456 116.338 226.39 112.914 229.072 108.268C235.898 96.445 231.848 81.3271 220.025 74.5011C208.202 67.6752 193.084 71.726 186.258 83.5489C183.575 88.1951 182.577 93.3141 183.055 98.2566C183.757 105.52 180.451 112.021 176.774 116.181C173.177 120.251 166.529 125.016 158.05 123.973C139.293 121.666 120.232 126.655 105.013 137.832C96.9211 143.775 86.7329 142.04 80.431 136.899C79.6694 136.278 78.8426 135.705 77.9498 135.189C69.0826 130.07 57.7442 133.108 52.6247 141.975C47.5052 150.842 50.5434 162.181 59.4106 167.3L219.965 259.997C228.832 265.116 240.171 262.078 245.29 253.211C250.41 244.344 247.372 233.005 238.504 227.886C237.612 227.37 236.702 226.941 235.783 226.592C228.18 223.705 221.584 215.749 222.684 205.769C224.754 187.001 219.544 168 208.168 152.909C203.026 146.087 203.828 137.948 205.554 132.797C207.319 127.533 211.295 121.419 217.937 118.396ZM223.057 129.644C229.843 126.555 235.764 121.396 239.776 114.448C250.015 96.7133 243.939 74.0364 226.204 63.7975C208.47 53.5586 185.793 59.6348 175.554 77.3692C171.542 84.3179 170.035 92.0254 170.753 99.4462C171.309 105.194 165.29 112.411 159.558 111.706C137.67 109.014 115.454 114.83 97.6975 127.87C94.8922 129.93 90.9405 129.522 88.2435 127.322C86.9706 126.284 85.5981 125.334 84.1296 124.486C69.3509 115.953 50.4535 121.017 41.9211 135.796C33.3886 150.574 38.4522 169.472 53.2308 178.004L213.785 270.7C228.564 279.233 247.461 274.169 255.994 259.391C264.526 244.612 259.463 225.715 244.684 217.182C243.216 216.334 241.707 215.621 240.171 215.037C236.917 213.802 234.588 210.584 234.969 207.124C237.384 185.227 231.312 163.079 218.037 145.469C214.561 140.858 217.802 132.037 223.057 129.644Z" fill="#482402"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M165.841 146.865C165.015 150.176 161.661 152.192 158.35 151.366C154.66 150.446 147.888 149.772 140.447 151.099C133.077 152.413 125.398 155.621 119.302 162.121C116.967 164.61 113.056 164.736 110.567 162.401C108.077 160.066 107.952 156.155 110.287 153.666C118.636 144.764 128.983 140.589 138.277 138.931C147.501 137.286 156.031 138.051 161.339 139.374C164.651 140.199 166.666 143.553 165.841 146.865Z" fill="#482402"/>
      </g>
      <defs>
        <filter id="filter0_d" x="24.4717" y="0" width="318.769" height="311.102" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
          <feOffset dx="4" dy="4"/>
          <feGaussianBlur stdDeviation="3"/>
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear" x1="72.819" y1="130.457" x2="258.829" y2="238.603" gradientUnits="userSpaceOnUse">
          <stop stopColor="#EFC443"/>
          <stop offset="1" stopColor="#E69536"/>
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Icon;
