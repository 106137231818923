import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GamesState, GameTableState } from 'state/types';
import { getBetVRFTxId, getGameBetsData, getGameState } from './helpers';
import { gameTokenIds, flipTableIds, allTableConfigs, SUPPORTED_MODULOS } from '../../config/constants/betTableConfigs';
import { mergeArrays } from '../../utils/array';

const initialState: GamesState = {
  tables: gameTokenIds.reduce((acc, gameType) => {
    const gameState: GameTableState = {
      public: {
        paused: true,
        allBets: [],
        allBetCount: 0,
        gapRate: 0,
        totalRate: 0,
        playerEndBlock: 0,
        bankerEndBlock: 0,
        offChainFeeAmount: null,
        onChainFeeAmount: null,
        bankerAmount: null,
        minBetAmount: null,
        maxBetRatio: 0,
      },
      user: {
        userBets: [],
        userBetCount: 0,
        canWithdrawTokenAmount: null,
        withdrawFeeRatio: null,
      },
      betReports: {
        private: {},
      }
    }
    return { ...acc, [gameType]: gameState }
  }, {}),
  dataLoaded: false,
  userDataLoaded: false,
  isLoading: false,
  currentGameTokenId: gameTokenIds[1],
  currentTableId: flipTableIds[1],
}

// Thunks
export const loadGameTablesState = createAsyncThunk<{ [key: number]: GameTableState }, { account: string, isFullLoad: boolean }>(
  'game/initialize',
  async ({ account, isFullLoad }) => {
    const result = await getGameState(account)
    const bets = await getGameBetsData(result, account, isFullLoad)
    gameTokenIds.forEach(gameTokenId => {
      const { allBets, userBets } = bets[gameTokenId]
      result[gameTokenId].public.allBets = allBets
      result[gameTokenId].user.userBets = userBets
    })
    return result
})

export const fetchGameBetVRFTransaction = createAsyncThunk<string, { tableId: number, betId: number, block: number }>(
  'game/fetchGameBetVRFTransaction',
  async ({ tableId, betId, block }) => getBetVRFTxId(tableId, betId, block),
)

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    setDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload
    },
    setCurrentTableId: (state, action: PayloadAction<number>) => {
      state.currentTableId = action.payload
      state.currentGameTokenId = allTableConfigs[action.payload].gameTokenId
    },
    setGameBetReported: (state, action: PayloadAction<{ tableId: number, round: number }>) => {
      const { tableId, round } = action.payload
      state.tables[allTableConfigs[tableId].gameTokenId].betReports.private[round] = true
    },
    resetGameUserData: (state) => {
      gameTokenIds.forEach(gameTokenId => {
        state.tables[gameTokenId].user.userBets = []
        state.tables[gameTokenId].user.userBetCount = 0
        state.tables[gameTokenId].user.withdrawFeeRatio = 0
        state.tables[gameTokenId].user.canWithdrawTokenAmount = null
        state.userDataLoaded = false
      })
    },
    setLastGameTxHash: (state, action: PayloadAction<{ tableId: number, txHash: string }>) => {
      const { tableId, txHash } = action.payload
      state.tables[allTableConfigs[tableId].gameTokenId].user.lastTxHash = txHash;
    }
  },
  extraReducers: (builder) => {
    // Initialize flip
    builder.addCase(loadGameTablesState.pending, (state, action) => {
      return {
        ...state,
        isLoading: true,
      }
    })
    builder.addCase(fetchGameBetVRFTransaction.fulfilled, (state, action) => {
      const { tableId } = action.meta.arg
      const table = state.tables[allTableConfigs[tableId].gameTokenId]
      table.user.lastTxHash = action.payload
    })

    builder.addCase(loadGameTablesState.fulfilled, (state, action) => {
      const userDataLoaded = action.meta.arg.isFullLoad
      const tables = action.payload
      const newGames: { [key: number]: GameTableState } = JSON.parse(JSON.stringify(state.tables))
      gameTokenIds.forEach(gameTokenId => {
        tables[gameTokenId].betReports = JSON.parse(JSON.stringify(state.tables[gameTokenId].betReports))
        tables[gameTokenId].user.lastTxHash = state.tables[gameTokenId].user.lastTxHash
        SUPPORTED_MODULOS.forEach(modulo => {
          const userBets = tables[gameTokenId].user.userBets.filter((bet) => bet.modulo === modulo)
          if (userBets.length) {
            const lastBet = userBets[userBets.length - 1]
            const found = newGames[gameTokenId].user.userBets.find(bet => bet.round === lastBet.round)
            if (!found) {
              tables[gameTokenId].betReports.private[lastBet.round] = lastBet.isSettled
            }
          }
        })
        tables[gameTokenId].public.allBets = mergeArrays(newGames[gameTokenId].public.allBets, tables[gameTokenId].public.allBets, 'round')
        tables[gameTokenId].user.userBets = mergeArrays(newGames[gameTokenId].user.userBets, tables[gameTokenId].user.userBets, 'round')
      })

      return {
        ...state,
        tables,
        userDataLoaded: state.userDataLoaded || userDataLoaded,
        dataLoaded: true,
        isLoading: false,
      }
    })
  },
})


// Actions
export const { setDataLoading, resetGameUserData, setGameBetReported, setLastGameTxHash, setCurrentTableId } = gameSlice.actions

export default gameSlice.reducer
