import { ethers } from 'ethers';
import { simpleRpcProvider } from 'utils/providers';
import tokens from 'config/constants/tokens';

// Addresses
import {
  getAddress,
  getBetMiningAddress,
  getBetMiningDistributorAddress,
  getGameAddress,
  getLotteryAddress,
  getLuckyPowerAddress,
  getMainAirdropAddress,
  getMasterChefAddress,
  getMulticallAddress,
  getOracleAddress,
  getReferralAddress,
} from 'utils/addressHelpers';

// ABI
import bep20Abi from 'config/abi/erc20.json';
import luckychipAbi from 'config/abi/luckychip.json';
import masterChef from 'config/abi/masterchef.json';
import MultiCallAbi from 'config/abi/Multicall.json';
import gameAbi from 'config/abi/game.json';
import luckyPowerAbi from 'config/abi/lucky-power.json';
import betMiningAbi from 'config/abi/bet-mining.json';
import betMiningDistributorAbi from 'config/abi/bet-mining-distributor.json';
import lotteryAbi from 'config/abi/lottery.json';
import oracleAbi from 'config/abi/oracle.json';
import referralAbi from 'config/abi/referral.json';
import bnbDiceReferralAbi from 'config/abi/bnb-dice-referral.json';
import diceReferralAbi from 'config/abi/dice-referral.json';
import airdropAbi from 'config/abi/main-airdrop.json';
import pairAbi from 'config/abi/pair.json';
import { DiceContract } from './types';
import { allTableConfigs } from '../config/constants/betTableConfigs';
import { BetTableType } from '../config/constants/types';

const getContract = (abi: any, address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  const signerOrProvider = signer ?? simpleRpcProvider;
  return new ethers.Contract(address, abi, signerOrProvider);
};

export const getPairContract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(pairAbi, address, signer);
};

export const getBep20Contract = (address: string, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(bep20Abi, address, signer);
};
export const getLCContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(luckychipAbi, tokens.lc.address, signer);
};
export const getMasterchefContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(masterChef, getMasterChefAddress(), signer);
};

export const getGameContract = (gameTokenId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(gameAbi, getGameAddress(gameTokenId), signer) as DiceContract;
}

export const getMulticallContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(MultiCallAbi, getMulticallAddress(), signer);
};

export const getLuckyPowerContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(luckyPowerAbi, getLuckyPowerAddress(), signer);
}

export const getBetMiningContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(betMiningAbi, getBetMiningAddress(), signer);
}

export const getBetMiningDistributorContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(betMiningDistributorAbi, getBetMiningDistributorAddress(), signer);
}

export const getAirdropContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(airdropAbi, getMainAirdropAddress(), signer);
}

export const getLotteryContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(lotteryAbi, getLotteryAddress(), signer);
}

export const getOracleContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(oracleAbi, getOracleAddress(), signer);
}

export const getReferralContract = (signer?: ethers.Signer | ethers.providers.Provider) => {
  return getContract(referralAbi, getReferralAddress(), signer);
}

export const getDiceReferralContract = (diceId: number, signer?: ethers.Signer | ethers.providers.Provider) => {
  const diceConfig = allTableConfigs[diceId]
  const address = getAddress(diceConfig.diceReferralContractAddress)
  const isBnbDice = diceConfig.tableId === BetTableType.BNB_DICE
  return getContract(isBnbDice ? bnbDiceReferralAbi : diceReferralAbi, address, signer)
}
