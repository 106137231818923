import { useSelector } from 'react-redux';
import { BanksState, BankState, State } from '../types';

export const useBanks = (): BanksState => {
  return useSelector((state: State) => state.banks)
}

export const useBankByTableId = (tableId: number): BankState => {
  const banks = useSelector((state: State) => state.banks)
  return banks.banks[tableId]
}
