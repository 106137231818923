import React from "react";
import { Svg, SvgProps } from '@lucky-chip/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M5 5L6 4H15.5L17 5V17L16 18H7L5 17V5Z" fill="white"/>
      <path d="M13.7225 11.6875H13.0167H8.73584V14.2817H9.93667H13.7225C14.5933 14.2817 15.3083 13.695 15.3083 12.98C15.3083 12.265 14.5933 11.6875 13.7225 11.6875Z" fill="#EB8F22"/>
      <path d="M11 1.83334C5.93998 1.83334 1.83331 5.94001 1.83331 11C1.83331 16.06 5.93998 20.1667 11 20.1667C16.06 20.1667 20.1666 16.06 20.1666 11C20.1666 5.94001 16.06 1.83334 11 1.83334ZM13.7225 15.6567H12.21V16.9583C12.21 17.3342 11.8983 17.6458 11.5225 17.6458C11.1466 17.6458 10.835 17.3342 10.835 16.9583V15.6567H9.93665H9.72581V16.9583C9.72581 17.3342 9.41415 17.6458 9.03831 17.6458C8.66248 17.6458 8.35081 17.3342 8.35081 16.9583V15.6567H8.04831H6.46248C6.08665 15.6567 5.77498 15.345 5.77498 14.9692C5.77498 14.5933 6.08665 14.2817 6.46248 14.2817H7.36081V11V7.71834H6.46248C6.08665 7.71834 5.77498 7.40668 5.77498 7.03084C5.77498 6.65501 6.08665 6.34334 6.46248 6.34334H8.04831H8.35081V5.04168C8.35081 4.66584 8.66248 4.35418 9.03831 4.35418C9.41415 4.35418 9.72581 4.66584 9.72581 5.04168V6.34334H9.93665H10.835V5.04168C10.835 4.66584 11.1466 4.35418 11.5225 4.35418C11.8983 4.35418 12.21 4.66584 12.21 5.04168V6.34334H13.0166C14.4375 6.34334 15.6933 7.59001 15.6933 9.02001C15.6933 9.63418 15.4733 10.1933 15.125 10.6517C16.0508 11.1008 16.6833 11.9808 16.6833 12.9983C16.6833 14.4558 15.3541 15.6567 13.7225 15.6567Z" fill="#EB8F22"/>
      <path d="M14.3183 9.01083C14.3183 8.40583 13.75 7.70917 13.0167 7.70917H9.93667H8.73584V10.3033H13.0167C13.7317 10.3125 14.3183 9.72583 14.3183 9.01083Z" fill="#EB8F22"/>
    </Svg>
  );
};

export default Icon;
