import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ReferralState } from 'state/types';
import { getReferralsData } from './helpers';

const initialState: ReferralState = {
  lpCommission: null,
  pendingLpCommission: null,
  betCommission: null,
  pendingBetCommission: null,
  rankCommission: null,
  pendingRankCommission: null,
  referralsCount: null,
}

// Thunks
export const initializeReferrals = createAsyncThunk<ReferralState, string>('referrals/initialize', getReferralsData)

export const referralsSlice = createSlice({
  name: 'referrals',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    // Initialize dice
    builder.addCase(initializeReferrals.pending, (state, action) => {
      return initialState
    })
    builder.addCase(initializeReferrals.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
  },
})

export default referralsSlice.reducer
