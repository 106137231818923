import React from "react";
import { Svg, SvgProps } from '@lucky-chip/uikit'

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <rect width="18" height="18" rx="9" fill="#7E7CE0"/>
      <path d="M5.60002 7.06665L8.77999 5.65332C8.91999 5.59332 9.08001 5.59332 9.21334 5.65332L12.3934 7.06665C12.6734 7.19332 12.9333 6.85332 12.74 6.61332L9.40666 2.53999C9.17999 2.25999 8.80667 2.25999 8.58 2.53999L5.24667 6.61332C5.06 6.85332 5.32002 7.19332 5.60002 7.06665Z" fill="white"/>
      <path d="M5.59998 10.9333L8.78663 12.3467C8.92663 12.4067 9.08665 12.4067 9.21998 12.3467L12.4066 10.9333C12.6866 10.8067 12.9466 11.1467 12.7533 11.3867L9.41997 15.46C9.1933 15.74 8.81998 15.74 8.59331 15.46L5.25998 11.3867C5.05998 11.1467 5.31332 10.8067 5.59998 10.9333Z" fill="white"/>
      <path d="M8.85331 7.32666L6.09998 8.69999C5.85331 8.81999 5.85331 9.17332 6.09998 9.29332L8.85331 10.6667C8.94665 10.7133 9.05995 10.7133 9.15328 10.6667L11.9066 9.29332C12.1533 9.17332 12.1533 8.81999 11.9066 8.69999L9.15328 7.32666C9.05328 7.27999 8.94665 7.27999 8.85331 7.32666Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
