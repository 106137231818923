import { ChainId } from '@luckychip/luckychip-sdk';
import BigNumber from 'bignumber.js/bignumber';
import { BIG_TEN } from 'utils/bigNumber';

export const IS_LUCKY_SEASON = false

BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

export const BASE_BSC_SCAN_URLS = {
  [ChainId.MAINNET]: 'https://bscscan.com',
  [ChainId.TESTNET]: 'https://testnet.bscscan.com',
}

// LC_PER_BLOCK details
// 40 LC is minted per block
// 20 LC per block is sent to Burn pool (A farm just for burning cake)
// 10 LC per block goes to LC syrup pool
// 9 LC per block goes to Yield farms and lottery
// LC_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
export const LC_PER_BLOCK = 40
export const BLOCKS_PER_YEAR = (60 / BSC_BLOCK_TIME) * 60 * 24 * 365 // 10512000
export const LC_PER_YEAR = LC_PER_BLOCK * BLOCKS_PER_YEAR
export const BASE_URL = 'https://luckychip.io'
export const BASE_ADD_LIQUIDITY_URL = 'https://pancakeswap.finance/add'
export const BASE_BSC_SCAN_URL = BASE_BSC_SCAN_URLS[ChainId.MAINNET]
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 1000000
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
