export function trimTrailingZeroes(s: string): string {
  const length = s.length
  let pos = -1
  for (let i = length - 1; i >= 0; i--) {
    if (s.charAt(i) !== '0') {
      pos = i
      break
    }
  }
  let result = s
  if (pos !== -1) {
    result = s.substring(0, pos + 1)
  }
  if (result.endsWith('.')) {
    result = result.substring(0, result.length - 1)
  }
  return result
}

export function cutDecimals(s: string, count = 18): string {
  const match = s.match(/(.*)\.(.*)/)
  if (match) {
    const int = match[1]
    const decimals = match[2]
    const cut = decimals.substring(0, Math.min(count, decimals.length))
    return `${int}.${cut}`
  }
  return s
}