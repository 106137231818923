import tokens from './tokens';
import { BetTableConfig, GameType, BetTableType, GameTokenId, GameConfig } from './types';
import addresses from './contracts';

// TODO: CONTRACT UPDATE
const oldLcDiceConfig: BetTableConfig = {
  tableId: BetTableType.OLD_LC_DICE,
  gameType: GameType.Dice,
  gameTokenId: GameTokenId.LC_GAME,
  depositToken: tokens.lc,
  gameToken: tokens.lcdice,
  contractAddress: addresses.lcDice,
  diceReferralContractAddress: addresses.lcDiceReferral,
}

const oldBnbDiceConfig: BetTableConfig = {
  tableId: BetTableType.OLD_BNB_DICE,
  gameType: GameType.Dice,
  gameTokenId: GameTokenId.BNB_GAME,
  depositToken: tokens.wbnb,
  gameToken: tokens.bnbdice,
  contractAddress: addresses.wbnbDice,
  diceReferralContractAddress: addresses.bnbDiceReferral,
}

const oldUsdtDiceConfig: BetTableConfig = {
  tableId: BetTableType.OLD_USDT_DICE,
  gameType: GameType.Dice,
  gameTokenId: GameTokenId.USDT_GAME,
  depositToken: tokens.usdt,
  gameToken: tokens.usdtdice,
  contractAddress: addresses.usdtDice,
  diceReferralContractAddress: addresses.usdtDiceReferral,
}

const oldBnbFlipConfig: BetTableConfig = {
  tableId: BetTableType.OLD_BNB_FLIP,
  gameType: GameType.Flip,
  gameTokenId: GameTokenId.BNB_GAME,
  depositToken: tokens.bnb,
  gameToken: tokens.bnbflip,
  contractAddress: addresses.bnbFlip,
  isSwapBet: false,
}

const lcDiceConfig: BetTableConfig = {
  tableId: BetTableType.LC_DICE,
  gameType: GameType.Dice,
  gameTokenId: GameTokenId.LC_GAME,
  depositToken: tokens.lc,
  gameToken: tokens.lcgame,
  contractAddress: addresses.lcGame,
  isSwapBet: false,
}

const bnbDiceConfig: BetTableConfig = {
  tableId: BetTableType.BNB_DICE,
  gameType: GameType.Dice,
  gameTokenId: GameTokenId.BNB_GAME,
  depositToken: tokens.bnb,
  gameToken: tokens.bnbgame,
  contractAddress: addresses.bnbGame,
  isSwapBet: false,
}

const bananaDiceConfig: BetTableConfig = {
  tableId: BetTableType.BANANA_DICE,
  gameType: GameType.Dice,
  gameTokenId: GameTokenId.BNB_GAME,
  depositToken: tokens.bnb,
  gameToken: tokens.bnbgame,
  contractAddress: addresses.bnbGame,
  isSwapBet: true,
  swapToken: tokens.banana,
  swapRouterId: 2,
  pairAddress: {
    56: '0xF65C1C0478eFDe3c19b49EcBE7ACc57BB6B1D713',
    97: '',
  },
  isToken0: true,
}

const babyDiceConfig: BetTableConfig = {
  tableId: BetTableType.BABY_DICE,
  gameType: GameType.Dice,
  gameTokenId: GameTokenId.BNB_GAME,
  depositToken: tokens.bnb,
  gameToken: tokens.bnbgame,
  contractAddress: addresses.bnbGame,
  isSwapBet: true,
  swapToken: tokens.baby,
  swapRouterId: 3,
  pairAddress: {
    56: '0x36aE10A4d16311959b607eE98Bc4a8A653A33b1F',
    97: '',
  },
  isToken0: true,
}

const lcFlipConfig: BetTableConfig = {
  tableId: BetTableType.LC_FLIP,
  gameType: GameType.Flip,
  gameTokenId: GameTokenId.LC_GAME,
  depositToken: tokens.lc,
  gameToken: tokens.lcgame,
  contractAddress: addresses.lcGame,
  isSwapBet: false,
}

const bnbFlipConfig: BetTableConfig = {
  tableId: BetTableType.BNB_FLIP,
  gameType: GameType.Flip,
  gameTokenId: GameTokenId.BNB_GAME,
  depositToken: tokens.bnb,
  gameToken: tokens.bnbgame,
  contractAddress: addresses.bnbGame,
  isSwapBet: false,
}

const bananaFlipConfig: BetTableConfig = {
  tableId: BetTableType.BANANA_FLIP,
  gameType: GameType.Flip,
  gameTokenId: GameTokenId.BNB_GAME,
  depositToken: tokens.bnb,
  gameToken: tokens.bnbgame,
  contractAddress: addresses.bnbGame,
  isSwapBet: true,
  swapToken: tokens.banana,
  swapRouterId: 2,
  pairAddress: {
    56: '0xF65C1C0478eFDe3c19b49EcBE7ACc57BB6B1D713',
    97: '',
  },
  isToken0: true,
}

const babyFlipConfig: BetTableConfig = {
  tableId: BetTableType.BABY_FLIP,
  gameType: GameType.Flip,
  gameTokenId: GameTokenId.BNB_GAME,
  depositToken: tokens.bnb,
  gameToken: tokens.bnbgame,
  contractAddress: addresses.bnbGame,

  isSwapBet: true,
  swapToken: tokens.baby,
  swapRouterId: 3,
  pairAddress: {
    56: '0x36aE10A4d16311959b607eE98Bc4a8A653A33b1F',
    97: '',
  },
  isToken0: true,
}

const lcGameConfig: GameConfig = {
  gameTokenId: GameTokenId.LC_GAME,
  depositToken: tokens.lc,
  gameToken: tokens.lcgame,
  contractAddress: addresses.lcGame,
}

const bnbGameConfig: GameConfig = {
  gameTokenId: GameTokenId.BNB_GAME,
  depositToken: tokens.bnb,
  gameToken: tokens.bnbgame,
  contractAddress: addresses.bnbGame,
}

export const allTableConfigs: { [key: number]: BetTableConfig } = {
  [BetTableType.OLD_LC_DICE]: oldLcDiceConfig,
  [BetTableType.OLD_BNB_DICE]: oldBnbDiceConfig,
  [BetTableType.OLD_USDT_DICE]: oldUsdtDiceConfig,
  [BetTableType.OLD_BNB_FLIP]: oldBnbFlipConfig,
  [BetTableType.LC_DICE]: lcDiceConfig,
  [BetTableType.BNB_DICE]: bnbDiceConfig,
  [BetTableType.BANANA_DICE]: bananaDiceConfig,
  [BetTableType.LC_FLIP]: lcFlipConfig,
  [BetTableType.BNB_FLIP]: bnbFlipConfig,
  [BetTableType.BANANA_FLIP]: bananaFlipConfig,
  [BetTableType.BABY_DICE]: babyDiceConfig,
  [BetTableType.BABY_FLIP]: babyFlipConfig,
}

export const oldDiceTableIds: BetTableType[] = [BetTableType.OLD_LC_DICE, BetTableType.OLD_BNB_DICE, BetTableType.OLD_USDT_DICE]
// export const diceTableIds: BetTableType[] = [BetTableType.LC_DICE, BetTableType.BNB_DICE, BetTableType.BANANA_DICE]
// export const flipTableIds: BetTableType[] = [BetTableType.LC_FLIP, BetTableType.BNB_FLIP, BetTableType.BANANA_FLIP]
export const diceTableIds: BetTableType[] = [BetTableType.LC_DICE, BetTableType.BNB_DICE, BetTableType.BABY_DICE, BetTableType.BANANA_DICE]
export const flipTableIds: BetTableType[] = [BetTableType.LC_FLIP, BetTableType.BNB_FLIP, BetTableType.BABY_FLIP, BetTableType.BANANA_FLIP]

export const betMiningPoolTableIds: BetTableType[] = [
  BetTableType.OLD_LC_DICE,
  BetTableType.OLD_BNB_DICE,
  BetTableType.OLD_USDT_DICE,
]

export const allGameConfigs: { [key: number]: GameConfig } = {
  [GameTokenId.LC_GAME]: lcGameConfig,
  [GameTokenId.BNB_GAME]: bnbGameConfig,
}

export const gameTokenIds: GameTokenId[] = [GameTokenId.LC_GAME, GameTokenId.BNB_GAME]

export const ALL_BANKS: BetTableConfig[] = [oldLcDiceConfig, oldBnbDiceConfig, oldUsdtDiceConfig, oldBnbFlipConfig, lcFlipConfig, bnbFlipConfig]

export const ACTIVE_BANKS: BetTableConfig[] = [lcFlipConfig, bnbFlipConfig]

export const EXPIRED_BANKS: BetTableConfig[] = [oldLcDiceConfig, oldBnbDiceConfig, oldUsdtDiceConfig, oldBnbFlipConfig]

export const SUPPORTED_MODULOS: number[] = [2, 6]

export const REWARD_RATE = 0.97

// Estimated number of seconds it takes to submit a transaction (3 blocks) in seconds
export const ROUND_BUFFER = 9

export const MAX_ROUND_FETCH_COUNT = 300

export const PUBLIC_PAST_ROUND_COUNT_DESKTOP = 40
export const PUBLIC_PAST_ROUND_COUNT_MOBILE = 25
export const PUBLIC_ROUND_DISPLAY_COUNT_IN_ROW_DESKTOP = 10
export const PUBLIC_ROUND_DISPLAY_COUNT_IN_ROW_MOBILE = 5

export const PRIVATE_PAST_ROUND_COUNT_DESKTOP = 25
export const PRIVATE_PAST_ROUND_COUNT_MOBILE = 12
export const PRIVATE_ROUND_DISPLAY_COUNT_IN_ROW_DESKTOP = 5
export const PRIVATE_ROUND_DISPLAY_COUNT_IN_ROW_MOBILE = 2
