import { MenuEntry } from '@lucky-chip/uikit';
import { ContextApi } from 'contexts/Localization/types';

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: t('Home'),
    icon: 'HomeIcon',
    href: '/',
  },
  // {
  //   label: t('Trade'),
  //   icon: 'TradeIcon',
  //   items: [
  //     {
  //       label: t('Exchange'),
  //       href: '/swap',
  //     },
  //     {
  //       label: t('Liquidity'),
  //       href: '/liquidity',
  //     },
  //   ],
  // },
  {
    label: t('Play to Earn'),
    icon: 'DiceIcon',
    items: [
      // {
      //   label: t('Lucky Dice (Public)'),
      //   href: '/play/dice-public',
      // },
      {
        label: t('Lucky Flip'),
        href: '/play/lucky-flip',
      },
      {
        label: t('Lucky Dice'),
        href: '/play/dice',
      },
      {
        label: t('Lucky Lottery'),
        icon: 'TicketIcon',
        href: '/lottery',
      },
    ]
  },
  {
    label: ('Stake to Earn'),
    icon: 'BankIcon',
    items: [
      {
        label: t('Lucky Banker'),
        icon: 'BankIcon',
        href: '/bank',
      },
      {
        label: t('Lucky Farmer'),
        icon: 'FarmIcon',
        href: '/farms',
      },
    ],
  },
  {
    label: t('Refer to Earn'),
    icon: 'ReferralsIcon',
    href: '/referrals',
  },
  {
    label: t('Lucky Profit'),
    icon: 'BonusIcon',
    href: '/bonus',
  },
  {
    label: t('Docs & Audits'),
    icon: 'AuditIcon',
    items: [
      {
        label: 'Docs',
        href: 'https://docs.luckychip.io/season.html',
      },
      {
        label: 'CertiK',
        href: 'certik',
      },
      {
        label: 'PeckShield 1st',
        href: 'peckshield1',
      },
      {
        label: 'PeckShield 2nd',
        href: 'peckshield2',
      },
      {
        label: 'PeckShield 3rd',
        href: 'peckshield3',
      },
      {
        label: 'Github',
        href: 'https://github.com/luckychip-io',
      },
    ],
  },
]

export default config
