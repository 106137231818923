import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { load, save } from 'redux-localstorage-simple';
import { useDispatch } from 'react-redux';
import farmsReducer from './farms';
import gamesReducer from './game';
import blockReducer from './block';
import banksReducer from './bank';
import airdropReducer from './airdrop';
import homeReducer from './home';
import referralReducer from './referral';
import diceReferralReducer from './dice-referral';
import oracleReducer from './oracle';
import rollerReducer from './roller';
import betMiningReducer from './bet-mining';
import bonusReducer from './bonus';
import lotteryReducer from './lottery';
import marketReducer from './market';
import whitelistReducer from './whitelist';
import apiReducer from './api';
import airdropNotifReducer from './airdrop-notif';
import { updateVersion } from './global/actions';
import user from './user/reducer';
import transactions from './transactions/reducer';
import swap from './swap/reducer';
import mint from './mint/reducer';
import lists from './lists/reducer';
import burn from './burn/reducer';
import multicall from './multicall/reducer';

const PERSISTED_KEYS: string[] = ['user', 'transactions', 'lists']

const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: {
    block: blockReducer,
    farms: farmsReducer,
    games: gamesReducer,
    banks: banksReducer,
    home: homeReducer,
    oracle: oracleReducer,
    betMining: betMiningReducer,
    referrals: referralReducer,
    diceReferrals: diceReferralReducer,
    bonus: bonusReducer,
    market: marketReducer,
    api: apiReducer,
    lottery: lotteryReducer,
    roller: rollerReducer,
    whitelist: whitelistReducer,
    airdrop: airdropReducer,
    airdropNotif: airdropNotifReducer,
    // Exchange
    user,
    transactions,
    swap,
    mint,
    burn,
    multicall,
    lists,
  },
  middleware: [...getDefaultMiddleware({ thunk: true }), save({ states: PERSISTED_KEYS })],
  preloadedState: load({ states: PERSISTED_KEYS }),
})

store.dispatch(updateVersion())

/**
 * @see https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
 */
export type AppDispatch = typeof store.dispatch
export type AppState = ReturnType<typeof store.getState>
export const useAppDispatch = () => useDispatch()

export default store
