import React, { lazy } from 'react';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import { ResetCSS } from '@lucky-chip/uikit';
import BigNumber from 'bignumber.js';
import queryString from 'query-string';
import useEagerConnect from 'hooks/useEagerConnect';
import { usePollBlockNumber } from 'state/block/hooks';
import { usePollAPIData, usePollCoreFarmData, usePollMarketData, usePollOraclePriceData } from 'state/farms/hooks';
import { DatePickerPortal } from 'components/DatePicker';
import GlobalStyle from './style/Global';
import Menu from './components/Menu';
import SuspenseWithChunkError from './components/SuspenseWithChunkError';
import { ToastListener } from './contexts/ToastsContext';
import PageLoader from './components/Loader/PageLoader';
import history from './routerHistory';
// Views included in the main bundle
import { isValidWalletAddress } from './utils/addressHelpers';
import {
  certikLink,
  deckLink,
  peckShieldLink1,
  peckShieldLink2,
  peckShieldLink3,
} from './config/constants/externalLinks';
import { usePollLottery } from './views/Reward/hooks/usePollLottery';
import { ComingSoonBetMining, ComingSoonGeneral } from './views/ComingSoon';
import { IS_LUCKY_SEASON } from './config';
import usePollBetMiningData from './views/BetMining/hooks/usePollBetMiningData';
import AirdropNotifModal from './views/Home/components/AirdropNotif';
import usePollFlips from './views/CoinFlip/hooks/usePollFlips';

// Route-based code splitting
// Only pool is included in the main bundle because of it's the most visited page
const Home = lazy(() => import('./views/Home'))
const Farms = lazy(() => import('./views/Farms'))
const NotFound = lazy(() => import('./views/NotFound'))
const DicePrivate = lazy(() => import('./views/DicePrivate'))
const CoinFlip = lazy(() => import('./views/CoinFlip'))
const AddLiquidity = lazy(() => import('./views/AddLiquidity'))
const Liquidity = lazy(() => import('./views/Pool'))
const PoolFinder = lazy(() => import('./views/PoolFinder'))
const RemoveLiquidity = lazy(() => import('./views/RemoveLiquidity'))
const Bank = lazy(() => import('./views/Bank'))
const ExpiredBank = lazy(() => import('./views/ExpiredBank'))
const Referrals = lazy(() => import('./views/Referrals'))
const Reward = lazy(() => import('./views/Reward'))
const BetMining = lazy(() => import('./views/BetMining'))
const Bonus = lazy(() => import('./views/Bonus'))
const Lottery = lazy(() => import('./views/Reward'))
const UiTest = lazy(() =>  import('./views/UiTest'))

// This config is required for number formatting
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  usePollBlockNumber()
  useEagerConnect()
  usePollCoreFarmData()
  usePollOraclePriceData()
  usePollMarketData()
  usePollAPIData()
  usePollLottery()
  usePollFlips()
  usePollBetMiningData()

  const params = queryString.parse(window.location.search)
  if (Object.keys(params)) {
    const referrer = String(params.referrer)
    if (referrer && isValidWalletAddress(referrer)) {
      localStorage.setItem('referrer', referrer)
    }
  }

  return (
    <Router history={history}>
      <ResetCSS />
      <GlobalStyle />
      <AirdropNotifModal/>
      <Menu>
        <SuspenseWithChunkError fallback={<PageLoader />}>
          <Switch>
            <Route path="/" exact>
              <Home />
            </Route>
            <Route path="/farms">
              {IS_LUCKY_SEASON ? <ComingSoonGeneral/> : <Farms />}
            </Route>
            <Route exact path="/play/dice">
              <DicePrivate />
            </Route>
            <Route exact path="/play/lucky-flip">
              <CoinFlip />
            </Route>
            <Route exact path="/referrals">
              <Referrals />
            </Route>
            <Route exact path="/bet-mining">
              {IS_LUCKY_SEASON ? <ComingSoonBetMining/> : <BetMining />}
            </Route>
            {/* <Route exact path="/bonus"> {IS_LUCKY_SEASON ? <ComingSoonGeneral/> : <Bonus />} </Route> */}
            <Route exact path="/bonus">
              <ComingSoonGeneral/>
            </Route>
            <Route exact path="/lottery">
              <Lottery />
            </Route>
            <Route exact path="/reward">
              <Reward />
            </Route>
            <Route exact path="/bank">
              <Bank />
            </Route>
            <Route exact path="/expired-bank">
              <ExpiredBank />
            </Route>
            <Route exact path="/test">
              <UiTest />
            </Route>
            {/* Using this format because these components use routes injected props. We need to rework them with hooks */}
            {/* <Route exact strict path="/swap" component={Swap} /> */}
            {/* <Route exact strict path="/swap/:outputCurrency" component={RedirectToSwap} /> */}
            {/* <Route exact strict path="/send" component={RedirectPathToSwapOnly} /> */}
            {/* <Route exact strict path="/find" component={PoolFinder} /> */}
            {/* <Route exact strict path="/liquidity" component={Liquidity} /> */}
            {/* <Route exact strict path="/create" component={RedirectToAddLiquidity} /> */}
            {/* <Route exact path="/add" component={AddLiquidity} /> */}
            {/* <Route exact path="/add/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} /> */}
            {/* <Route exact path="/add/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} /> */}
            {/* <Route exact path="/create" component={AddLiquidity} /> */}
            {/* <Route exact path="/create/:currencyIdA" component={RedirectOldAddLiquidityPathStructure} /> */}
            {/* <Route exact path="/create/:currencyIdA/:currencyIdB" component={RedirectDuplicateTokenIds} /> */}
            {/* <Route exact strict path="/remove/:tokens" component={RedirectOldRemoveLiquidityPathStructure} /> */}
            {/* <Route exact strict path="/remove/:currencyIdA/:currencyIdB" component={RemoveLiquidity} /> */}
            <Route exact path="/peckshield1" component={() => {
              window.location.href = peckShieldLink1;
              return null;
            }}/>
            <Route exact path="/peckshield2" component={() => {
              window.location.href = peckShieldLink2;
              return null;
            }}/>
            <Route exact path='/deck' component={() => {
              window.location.href = deckLink;
              return null;
            }}/>
            <Route exact path='/whitepaper' component={() => {
              window.location.href = deckLink;
              return null;
            }}/>
            <Route exact path='/docs' component={() => {
              window.location.href = 'https://docs.luckychip.io';
              return null;
            }}/>
            <Route exact path='/1st_audit_report_by_peckshield' component={() => {
              window.location.href = peckShieldLink1;
              return null;
            }}/>
            <Route exact path='/2nd_audit_report_by_certik' component={() => {
              window.location.href = 'https://www.certik.com/projects/luckychip'; return null;
            }}/>
            <Route exact path='/3rd_audit_report_by_peckshield' component={() => {
              window.location.href = peckShieldLink2;
              return null;
            }}/>
            <Route exact path='/certik' component={() => {
              window.location.href = certikLink;
              return null;
            }}/>
            <Route exact path='/peckshield1' component={() => {
              window.location.href = peckShieldLink1;
              return null;
            }}/>
            <Route exact path='/peckshield2' component={() => {
              window.location.href = peckShieldLink2;
              return null;
            }}/>
            <Route exact path='/peckshield3' component={() => {
              window.location.href = peckShieldLink3;
              return null;
            }}/>

            {/* Redirect */}
            <Route path="/pool">
              <Redirect to="/liquidity" />
            </Route>
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </SuspenseWithChunkError>
      </Menu>
      <ToastListener />
      <DatePickerPortal />
    </Router>
  )
}

export default React.memo(App)
