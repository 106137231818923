import { ethers } from 'ethers'; // Ethers
import MerkleTree from 'merkletreejs'; // MerkleTree.js
import keccak256 from 'keccak256';
import { AirdropState } from 'state/types';
import mainAirdropAbi from '../../config/abi/main-airdrop.json';
import playerAirdropAbi from '../../config/abi/player-airdrop.json';
import apeswapAirdropAbi from '../../config/abi/apeswap-airdrop.json';
import amaAirdropAbi from '../../config/abi/baby-ama-airdrop.json';
import { getBigNumberFromEthers } from '../../utils/formatBalance';
import { DEFAULT_TOKEN_DECIMAL } from '../../config';
import { getMainAirdropAddress, getPlayerAirdropAddress, getApeswapAirdropAddress, getAmaAirdropAddress } from '../../utils/addressHelpers';
import { multicallv2 } from '../../utils/multicall';

export const emptyAirdrop = {
  total: null,
  claimable: null,
  flipPlayed: null,
  dicePlayed: null,
}

export const AirdropData = {
  config: {
    decimals: 18,
    airdrop: {},
  },
  tree: null,
}

export const ApeswapAirdropData = {
  config: {
    decimals: 18,
    airdrop: {},
  },
  tree: null,
}

export const AmaAirdropData = {
  config: {
    decimals: 18,
    airdrop: {},
  },
  tree: null,
}

async function loadAirdropConfig() {
  const url = '/airdrop.json'
  if (Object.entries(AirdropData.config.airdrop).length === 0) {
    AirdropData.config = await fetch(url).then(d => d.json())
  }

  if (AirdropData.tree === null) {
    // Setup merkle tree
    AirdropData.tree = new MerkleTree(
      // Generate leafs
      Object.entries(AirdropData.config.airdrop).map(([address, tokens]) =>
        generateLeaf(
          ethers.utils.getAddress(address),
          ethers.utils.parseUnits(tokens.toString(), AirdropData.config.decimals).toString()
        )
      ),
      // Hashing function
      keccak256,
      {sortPairs: true}
    );
  }

  const apeUrl = '/apeswapAirdrop.json'
  if (Object.entries(ApeswapAirdropData.config.airdrop).length === 0) {
    ApeswapAirdropData.config = await fetch(apeUrl).then(d => d.json())
  }

  if (ApeswapAirdropData.tree === null) {
    // Setup merkle tree
    ApeswapAirdropData.tree = new MerkleTree(
      // Generate leafs
      Object.entries(ApeswapAirdropData.config.airdrop).map(([address, tokens]) =>
        generateLeaf(
          ethers.utils.getAddress(address),
          ethers.utils.parseUnits(tokens.toString(), ApeswapAirdropData.config.decimals).toString()
        )
      ),
      // Hashing function
      keccak256,
      {sortPairs: true}
    );
  }

  const apeAmaUrl = '/zbcAmaAirdrop.json'
  if (Object.entries(AmaAirdropData.config.airdrop).length === 0) {
    AmaAirdropData.config = await fetch(apeAmaUrl).then(d => d.json())
  }

  if (AmaAirdropData.tree === null) {
    // Setup merkle tree
    AmaAirdropData.tree = new MerkleTree(
      // Generate leafs
      Object.entries(AmaAirdropData.config.airdrop).map(([address, tokens]) =>
        generateLeaf(
          ethers.utils.getAddress(address),
          ethers.utils.parseUnits(tokens.toString(), AmaAirdropData.config.decimals).toString()
        )
      ),
      // Hashing function
      keccak256,
      {sortPairs: true}
    );
  }
}


/**
 * Generate Merkle Tree leaf from address and value
 * @param {string} address of airdrop claimee
 * @param {string} value of airdrop tokens to claimee
 * @returns {Buffer} Merkle Tree node
 */
export function generateLeaf(address: string, value: string): Buffer {
  return Buffer.from(
    // Hash in appropriate Merkle format
    ethers.utils
      .solidityKeccak256(["address", "uint256"], [address, value])
      .slice(2),
    "hex"
  );
}

export const getAirdropData = async (account: string): Promise<AirdropState> => {
  await loadAirdropConfig()

  if (!account) {
    return {
      main: emptyAirdrop,
      player: emptyAirdrop,
      apeswap: emptyAirdrop,
      ama: emptyAirdrop,
    }
  }
  const formattedAddress: string = ethers.utils.getAddress(account)
  const amount = AirdropData.config.airdrop[formattedAddress] || 0
  const numTokens: string = ethers.utils
    .parseUnits(amount.toString(), AirdropData.config.decimals)
    .toString()
  const mainAirdropContractAddress = getMainAirdropAddress()
  const leaf: Buffer = generateLeaf(formattedAddress, numTokens)
  const proof: string[] = AirdropData.tree.getHexProof(leaf)
  const mainCalls = [
    { address: mainAirdropContractAddress, name: 'canClaim', params: [formattedAddress, numTokens, proof] },
    { address: mainAirdropContractAddress, name: 'pendingAirdrop', params: [formattedAddress, numTokens, proof] },
    { address: mainAirdropContractAddress, name: 'isGamePlayed', params: [formattedAddress] },
  ]
  const mainCallResults = await multicallv2(mainAirdropAbi, mainCalls);
  const mainPendingAirdrop = mainCallResults[1][0]
  const isGamePlayed = mainCallResults[2][0]

  const playerAirdropContractAddress = getPlayerAirdropAddress()
  const playerCalls = [
    { address: playerAirdropContractAddress, name: 'pendingAirdrop', params: [formattedAddress] },
    { address: playerAirdropContractAddress, name: 'isDicePlayed', params: [formattedAddress] },
    { address: playerAirdropContractAddress, name: 'singleAmount', params: [] },
  ]

  const playerCallResults = await multicallv2(playerAirdropAbi, playerCalls)
  const playerPendingAirdrop = playerCallResults[0][0]
  const { flipPlayed: playerFlipPlayed, dicePlayed: playerDicePlayed } = playerCallResults[1]

  const apeswapAmount = ApeswapAirdropData.config.airdrop[formattedAddress] || 0
  const apeswapNumTokens: string = ethers.utils
    .parseUnits(apeswapAmount.toString(), ApeswapAirdropData.config.decimals)
    .toString()
  const apeswapAirdropContractAddress = getApeswapAirdropAddress()
  const aweswapLeaf: Buffer = generateLeaf(formattedAddress, apeswapNumTokens)
  const apeswapProof: string[] = ApeswapAirdropData.tree.getHexProof(aweswapLeaf)
  const apeswapCalls = [
    { address: apeswapAirdropContractAddress, name: 'canClaim', params: [formattedAddress, apeswapNumTokens, apeswapProof] },
    { address: apeswapAirdropContractAddress, name: 'pendingAirdrop', params: [formattedAddress, apeswapNumTokens, apeswapProof] },
    { address: apeswapAirdropContractAddress, name: 'isGamePlayed', params: [formattedAddress] },
  ]
  const apeswapCallResults = await multicallv2(apeswapAirdropAbi, apeswapCalls);
  const apeswapPendingAirdrop = apeswapCallResults[1][0]
  const apeswapGamePlayed = apeswapCallResults[2][0]
  console.log(`apeswapAirdrop,${ApeswapAirdropData.tree.getHexRoot()},${formattedAddress},${apeswapNumTokens},${JSON.stringify(apeswapProof)},${apeswapAmount},${apeswapPendingAirdrop},${apeswapGamePlayed},${JSON.stringify(apeswapCallResults)}`)

  const amaAmount = AmaAirdropData.config.airdrop[formattedAddress] || 0
  const amaNumTokens: string = ethers.utils
    .parseUnits(amaAmount.toString(), AmaAirdropData.config.decimals)
    .toString()
  const amaAirdropContractAddress = getAmaAirdropAddress()
  const amaLeaf: Buffer = generateLeaf(formattedAddress, amaNumTokens)
  const amaProof: string[] = AmaAirdropData.tree.getHexProof(amaLeaf)
  const amaCalls = [
    { address: amaAirdropContractAddress, name: 'canClaim', params: [formattedAddress, amaNumTokens, amaProof] },
    { address: amaAirdropContractAddress, name: 'pendingAirdrop', params: [formattedAddress, amaNumTokens, amaProof] },
    { address: amaAirdropContractAddress, name: 'isGamePlayed', params: [formattedAddress] },
    { address: amaAirdropContractAddress, name: 'count', params: []},
  ]
  const amaCallResults = await multicallv2(amaAirdropAbi, amaCalls);
  const amaPendingAirdrop = amaCallResults[1][0]
  const amaGamePlayed = amaCallResults[2][0]
  const amaCount = amaCallResults[3][0]
  console.log(`amaAirdrop,${AmaAirdropData.tree.getHexRoot()},${formattedAddress},${amaCount},${amaNumTokens},${amaAmount},${amaPendingAirdrop},${amaGamePlayed},${JSON.stringify(apeswapCallResults)}`)

  return {
    main: {
      total: amount,
      claimable: getBigNumberFromEthers(mainPendingAirdrop).dividedBy(DEFAULT_TOKEN_DECIMAL).toNumber(),
      flipPlayed: isGamePlayed,
      dicePlayed: isGamePlayed,
    },
    player: {
      total: getBigNumberFromEthers(playerCallResults[2][0]).dividedBy(DEFAULT_TOKEN_DECIMAL).toNumber(),
      claimable: getBigNumberFromEthers(playerPendingAirdrop).dividedBy(DEFAULT_TOKEN_DECIMAL).toNumber(),
      flipPlayed: playerFlipPlayed,
      dicePlayed: playerDicePlayed,
    },
    apeswap: {
      total: apeswapAmount,
      claimable: getBigNumberFromEthers(apeswapPendingAirdrop).dividedBy(DEFAULT_TOKEN_DECIMAL).toNumber(),
      flipPlayed: apeswapGamePlayed,
      dicePlayed: apeswapGamePlayed,
    },
    ama: {
      total: amaAmount,
      claimable: getBigNumberFromEthers(amaPendingAirdrop).dividedBy(DEFAULT_TOKEN_DECIMAL).toNumber(),
      flipPlayed: amaGamePlayed,
      dicePlayed: amaGamePlayed,
      show: (amaCount.toNumber() < 1000),
    },
  }
};
