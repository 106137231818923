import { WhiteListState } from 'state/types';
import { multicallv2 } from 'utils/multicall';
import whiteListAbi from 'config/abi/whitelist.json';
import { getWhiteListAddress } from 'utils/addressHelpers';
import { getBigNumberFromEthers } from '../../utils/formatBalance';


export const getWhiteListData = async (account: string): Promise<WhiteListState> => {
  const address = getWhiteListAddress();
  const callNames = ['MAX_AMOUNT', 'MIN_AMOUNT', 'PRICE'];
  const userCallNames = ['balanceOf', 'pendingLC', 'canJoin'];
  const calls = callNames.map(name => ({address, name, params: []}));
  if (account) {
    const userCalls = userCallNames.map(name => ({address, name, params: [account]}));
    calls.push(...userCalls);
  }
  const result: WhiteListState = {
    pendingLC: null,
    minAmount: null,
    maxAmount: null,
    lcPrice: null,
    bnbBalance: null,
    isWhitelisted: false,
  };
  const callResults = await multicallv2(whiteListAbi, calls);
  result.maxAmount = getBigNumberFromEthers(callResults[0][0]).toJSON();
  result.minAmount = getBigNumberFromEthers(callResults[1][0]).toJSON();
  result.lcPrice = getBigNumberFromEthers(callResults[2][0]).toJSON();
  const callCount = callNames.length;
  if (account) {
    result.bnbBalance = getBigNumberFromEthers(callResults[callCount][0]).toJSON();
    result.pendingLC = getBigNumberFromEthers(callResults[callCount + 1][0]).toJSON();
    result.isWhitelisted = callResults[callCount + 2][0]
  }
  return result;
};
