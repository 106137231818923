import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { MarketState } from 'state/types'
import { getMarketData } from './helpers'

const initialState: MarketState = {
  lcSupply: null,
  tvl: null,
}

// Thunks
export const fetchMarket = createAsyncThunk<MarketState>('market/fetMarket', getMarketData)

export const marketDataSlice = createSlice({
  name: 'market',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMarket.fulfilled, (state, action) => {
        return { ...state, ...action.payload }
      })
  },
})


// Actions
export default marketDataSlice.reducer
