import { ethers } from 'ethers';
import { ChainId } from '@luckychip/luckychip-sdk';
import addresses from 'config/constants/contracts';
import { Address } from 'config/constants/types';
import { allGameConfigs, allTableConfigs } from '../config/constants/betTableConfigs';

export const getAddress = (address: Address): string => {
  if (!address) {
    return ''
  }
  const chainId = process.env.REACT_APP_CHAIN_ID
  const res = address[chainId] ? address[chainId] : address[ChainId.MAINNET]
  return ethers.utils.getAddress(res)
}

export const getWhiteListAddress = () => {
  return getAddress(addresses.whitelist)
}

export const getMasterChefAddress = () => {
  return getAddress(addresses.masterChef)
}
export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getFarmAuctionAddress = () => {
  return getAddress(addresses.farmAuction)
}
export const getGameAddress = (gameTokenId: number) => {
  const table = allGameConfigs[gameTokenId]
  return getAddress(table.contractAddress)
}

export const getLuckyPowerAddress = () => {
  return getAddress(addresses.luckyPower)
}

export const getBetMiningAddress = () => {
  return getAddress(addresses.betMining)
}

export const getBetMiningDistributorAddress = () => {
  return getAddress(addresses.betMiningDistributor)
}

export const getMainAirdropAddress = () => {
  return getAddress(addresses.mainAirdrop)
}

export const getPlayerAirdropAddress = () => {
  return getAddress(addresses.playerAirdrop)
}

export const getApeswapAirdropAddress = () => {
  return getAddress(addresses.apeswapAirdrop)
}

export const getAmaAirdropAddress = () => {
  return getAddress(addresses.zbcAmaAirdrop)
}

export const getLotteryAddress = () => {
  return getAddress(addresses.lottery)
}

export const getOracleAddress = () => {
  return getAddress(addresses.oracle)
}

export const getOracleUSDAddress = () => {
  return getAddress(addresses.oracleUsd)
}

export const getLCAddress = () => {
  return getAddress(addresses.lcToken)
}

export const getReferralAddress = () => {
  return getAddress(addresses.referral)
}

export const getDiceReferralAddress = (diceId: number) => {
  const diceConfig = allTableConfigs[diceId]
  return getAddress(diceConfig.diceReferralContractAddress)
}

export const isValidWalletAddress = (address: string) => {
  const a = address.trim()
  return a.startsWith('0x') && a.length === 42
}
