import { useMemo } from 'react';
import useActiveWeb3React from 'hooks/useActiveWeb3React';
import {
  getBep20Contract,
  getLCContract,
  getMasterchefContract,
} from 'utils/contractHelpers';
import { getMulticallAddress } from 'utils/addressHelpers';

// Imports below migrated from Exchange useContract.ts
import { Contract } from '@ethersproject/contracts';
import { ChainId, WETH } from '@luckychip/luckychip-sdk';
import { abi as IUniswapV2PairABI } from '@uniswap/v2-core/build/IUniswapV2Pair.json';
import ENS_PUBLIC_RESOLVER_ABI from '../config/abi/ens-public-resolver.json';
import ENS_ABI from '../config/abi/ens-registrar.json';
import { ERC20_BYTES32_ABI } from '../config/abi/erc20';
import ERC20_ABI from '../config/abi/erc20.json';
import WETH_ABI from '../config/abi/weth.json';
import multiCallAbi from '../config/abi/Multicall.json';
import { getContract } from '../utils';

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: string) => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getBep20Contract(address, library.getSigner()), [address, library]);
};

export const useLuckyChip = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getLCContract(library.getSigner()), [library]);
};

export const useMasterchef = () => {
  const { library } = useActiveWeb3React();
  return useMemo(() => getMasterchefContract(library.getSigner()), [library]);
};

// Code below migrated from Exchange useContract.ts

// returns null on errors
export function useContract(address: string | undefined, ABI: any, withSignerIfPossible = true): Contract | null {
  const { library, account } = useActiveWeb3React();

  return useMemo(() => {
    if (!address || !ABI || !library) return null;
    try {
      return getContract(address, ABI, library, withSignerIfPossible && account ? account : undefined);
    } catch (error) {
      console.error('Failed to get contract', error);
      return null;
    }
  }, [address, ABI, library, withSignerIfPossible, account]);
}

export function useTokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_ABI, withSignerIfPossible);
}

export function useWETHContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React();
  return useContract(chainId ? WETH[chainId].address : undefined, WETH_ABI, withSignerIfPossible);
}

export function useENSRegistrarContract(withSignerIfPossible?: boolean): Contract | null {
  const { chainId } = useActiveWeb3React();
  let address: string | undefined;
  if (chainId) {
    // eslint-disable-next-line default-case
    switch (chainId) {
      case ChainId.MAINNET:
      case ChainId.TESTNET:
        address = '0x00000000000C2E074eC69A0dFb2997BA6C7d2e1e';
        break;
    }
  }
  return useContract(address, ENS_ABI, withSignerIfPossible);
}

export function useENSResolverContract(address: string | undefined, withSignerIfPossible?: boolean): Contract | null {
  return useContract(address, ENS_PUBLIC_RESOLVER_ABI, withSignerIfPossible);
}

export function useBytes32TokenContract(tokenAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(tokenAddress, ERC20_BYTES32_ABI, withSignerIfPossible);
}

export function usePairContract(pairAddress?: string, withSignerIfPossible?: boolean): Contract | null {
  return useContract(pairAddress, IUniswapV2PairABI, withSignerIfPossible);
}

export function useMulticallContract(): Contract | null {
  return useContract(getMulticallAddress(), multiCallAbi, false);
}
