import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HomeState, PriceMap, SerializedBigNumber } from 'state/types';
import { getLuckyPowerAndBonus } from './helpers';

const initialState: HomeState = {
  luckyPower: null,
  bettingVolume: null,
  playBonus: null,
  bankerTVL: null,
  bankerBonus: null,
  referralsEarning: null,
  referralsBonus: null,
  farmTVL: null,
  farmBonus: null,
  luckyBonuses: {},
}

// Thunks

export const fetchLuckyPowerAndBonus = createAsyncThunk<HomeState, { account: string }>(
  'home/fetchLuckyPowerAndBonus',
  ({ account }) => {
    return getLuckyPowerAndBonus(account)
  }
)

export const homeDataSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setLuckyPower: (state, action: PayloadAction<SerializedBigNumber>) => {
      state.luckyPower = action.payload
    },
    setLuckyBonus: (state, action: PayloadAction<PriceMap>) => {
      state.luckyBonuses = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchLuckyPowerAndBonus.pending, (state) => {
        return {
          ...state,
          luckyPower: null,
          bettingVolume: null,
          playBonus: null,
          bankerTVL: null,
          bankerBonus: null,
          referralsEarning: null,
          referralsBonus: null,
          farmTVL: null,
          farmBonus: null,
          luckyBonuses: {},
        }
      })
    builder
      .addCase(fetchLuckyPowerAndBonus.fulfilled, (state, action) => {
        return { ...state, ...action.payload }
      })
  },
})


// Actions
export const { setLuckyBonus, setLuckyPower } = homeDataSlice.actions

export default homeDataSlice.reducer
