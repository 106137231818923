/* eslint no-bitwise: ["error", { "allow": ["&"] }] */
import { range } from 'lodash';
import { Bet, GamePublicData, GameTableState, GameUserData } from 'state/types';
import { multicallv2 } from 'utils/multicall';
import gameBnbAbi from 'config/abi/game-bnb.json';
import { getGameAddress } from 'utils/addressHelpers';
import { allTableConfigs, gameTokenIds, MAX_ROUND_FETCH_COUNT } from '../../config/constants/betTableConfigs';
import { getBigNumberFromEthers } from '../../utils/formatBalance';
import { getGameContract } from '../../utils/contractHelpers';

export const getGameState = async (account: string): Promise<{ [key: number]: GameTableState }> => {
  const publicMethods = [
    'paused', // [0]
    'getBetsLength', // [1] private all bet count
    'gapRate', // [2] private gap rate
    'TOTAL_RATE', // [3]
    'playerEndBlock', // [4]
    'bankerEndBlock', // [5]
    'offChainFeeAmount', // [6] off-chain fee amount
    'onChainFeeAmount', // [7] on-chain fee amount for chainlink VRF
    'bankerAmount', // [8]
    'minBetAmount', // [9]
    'maxBetRatio', // [10]
  ];
  const publicCallCount = publicMethods.length;
  const userMethods = [
    'getUserBetLength', // [0] private user bet count
    'canWithdrawToken', // [1]
  ];
  const userCallCount = userMethods.length;

  const commonGameCalls = gameTokenIds.reduce((acc, gameTokenId) => {
    const address = getGameAddress(gameTokenId);
    const calls = publicMethods.map(name => ({name, address, params: []}));
    if (account) {
      const userCalls = userMethods.map(name => ({name, address, params: [account]}));
      calls.push(...userCalls);
    }
    return [...acc, ...calls];
  }, []);

  const commonGameCallsResult = await multicallv2(gameBnbAbi, commonGameCalls);
  const result: { [key: number]: GameTableState } = {};
  gameTokenIds.forEach((gameTokenId, index) => {
    const frameWidth = account ? (publicCallCount + userCallCount) : publicCallCount;
    const callsFrame = commonGameCallsResult.slice(index * frameWidth, (index + 1) * frameWidth);
    const publicData: GamePublicData = {
      paused: callsFrame[0][0],
      allBets: [],
      allBetCount: callsFrame[1][0].toNumber(),
      gapRate: callsFrame[2][0].toNumber(),
      totalRate: callsFrame[3][0].toNumber(),
      playerEndBlock: callsFrame[4][0].toNumber(),
      bankerEndBlock: callsFrame[5][0].toNumber(),
      offChainFeeAmount: getBigNumberFromEthers(callsFrame[6][0]).toJSON(),
      onChainFeeAmount: getBigNumberFromEthers(callsFrame[7][0]).toJSON(),
      bankerAmount: getBigNumberFromEthers(callsFrame[8][0]).toJSON(),
      minBetAmount: getBigNumberFromEthers(callsFrame[9][0]).toJSON(),
      maxBetRatio: callsFrame[10][0].toNumber(),
    };

    const userData: GameUserData = {
      userBets: [],
      userBetCount: 0,
      withdrawFeeRatio: 0,
      canWithdrawTokenAmount: null,
    };
    if (account) {
      userData.userBetCount = callsFrame[publicCallCount][0].toNumber();
      userData.canWithdrawTokenAmount = getBigNumberFromEthers(callsFrame[publicCallCount + 1][0]).toJSON();
    }
    result[gameTokenId] = {
      public: publicData,
      user: userData,
      betReports: { private: {} },
    };
  });
  return result;
};

interface IGameBetsData {
  allBets: Bet[];
  userBets: Bet[];
}

export const getGameBetsData = async (tables: { [key: number]: GameTableState }, account: string, isFullLoad = false): Promise<{ [key: number]: IGameBetsData }> => {
  const result: { [key: number]: IGameBetsData } = {};
  const privateAllIndices: { [key: number]: number[] } = {};
  const privateUserIndices: { [key: number]: number[] } = {};

  gameTokenIds.forEach(gameTokenId => {
    // initialization
    privateAllIndices[gameTokenId] = []
    privateUserIndices[gameTokenId] = []
    result[gameTokenId] = {
      allBets: [],
      userBets: [],
    }

    const tableState = tables[gameTokenId];
    const { allBetCount } = tableState.public;
    if (isFullLoad) {
      privateAllIndices[gameTokenId] = range(Math.max(0, allBetCount - MAX_ROUND_FETCH_COUNT), allBetCount);
    } else if (allBetCount === 0) {
      privateAllIndices[gameTokenId] = [];
    } else {
      privateAllIndices[gameTokenId] = allBetCount === 1 ? [0] : [allBetCount - 2, allBetCount - 1];
    }
  });

  if (account) {
    const gameCalls = gameTokenIds.map(gameTokenId => {
      const address = getGameAddress(gameTokenId);
      const tableState = tables[gameTokenId];
      const { userBetCount } = tableState.user;
      let privateUserBetsFetchParams;
      if (isFullLoad) {
        privateUserBetsFetchParams = [account, Math.max(0, userBetCount - MAX_ROUND_FETCH_COUNT), userBetCount];
      } else {
        privateUserBetsFetchParams = [account, Math.max(0, userBetCount - 1), userBetCount];
      }
      return { address, name: 'getUserBets', params: privateUserBetsFetchParams };
    }, []);
    const diceCallResults = await multicallv2(gameBnbAbi, gameCalls);
    gameTokenIds.forEach((gameTokenId, index) => {
      const [, dicePrivateUserBetIndices] = diceCallResults[index];
      privateUserIndices[gameTokenId] = dicePrivateUserBetIndices.map(idx => idx.toNumber());
      privateAllIndices[gameTokenId] = [...new Set([...privateAllIndices[gameTokenId], ...privateUserIndices[gameTokenId]])];
    });
  }
  gameTokenIds.forEach(gameTokenId => {
    const sortFn = (a, b) => a > b ? 1 : -1
    privateAllIndices[gameTokenId] = privateAllIndices[gameTokenId].sort(sortFn)
    privateUserIndices[gameTokenId] = privateUserIndices[gameTokenId].sort(sortFn)
  })

  const roundsCalls = gameTokenIds.reduce((acc, gameTokenId) => {
    const address = getGameAddress(gameTokenId);
    const privateAllBets = privateAllIndices[gameTokenId].map(idx => ({address, name: 'bets', params: [idx]}));
    return [...acc, ...privateAllBets];
  }, []);

  // rounds calls frame structure
  // [<bets x privateAllIndicesLength>]

  const roundsFetchCalls = await multicallv2(gameBnbAbi, roundsCalls);
  let cursor = 0;
  gameTokenIds.forEach((gameTokenId, index) => {
    const privateAllIndicesLength = privateAllIndices[gameTokenId].length;
    for (let i = cursor; i < cursor + privateAllIndicesLength; i++) {
      const {gambler, blockNumber, amount, outcome, winAmount, mask, modulo, rollUnder, isSettled} = roundsFetchCalls[i];
      const bet: Bet = {
        round: blockNumber.toNumber(),
        betAmount: getBigNumberFromEthers(amount).toJSON(),
        winAmount: getBigNumberFromEthers(winAmount).toJSON(),
        modulo,
        mask,
        rollUnder,
        finalNumber: outcome.toNumber(),
        isSettled,
        betId: privateAllIndices[gameTokenId][i - cursor],
      };
      result[gameTokenId].allBets.push(bet);
      const roundIndex = privateAllIndices[gameTokenId][i - cursor]
      if (privateUserIndices[gameTokenId].includes(roundIndex)) {
        result[gameTokenId].userBets.push(result[gameTokenId].allBets[i - cursor])
      }
    }

    cursor += privateAllIndicesLength;
  });
  return result;
};

export const getBetVRFTxId = async (tableId: number, betId: number, block: number): Promise<string> => {
  try {
    const game = getGameContract(allTableConfigs[tableId].gameTokenId)
    const eventFilter = game.filters.BetSettled(betId);
    const events = await game.queryFilter(eventFilter, block);
    if (events.length) {
      return events[0].transactionHash
    }
    return null
  } catch (err) {
    console.log('err = ', err)
  }
  return null
}
