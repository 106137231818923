import { useSelector } from 'react-redux';
import { GamesState, GameTableState, State } from '../types';
import { allTableConfigs } from '../../config/constants/betTableConfigs';

export const useGames = (): GamesState => {
  return useSelector((state: State) => state.games)
}

export const useCurrentGame = (): GameTableState => {
  const { currentTableId } = useSelector((s: State) => s.games)
  return useSelector((s: State) => s.games.tables[allTableConfigs[currentTableId].gameTokenId])
}

export const useGameStateByTableId = (tableId: number): GameTableState => {
  return useSelector((s: State) => s.games.tables[allTableConfigs[tableId].gameTokenId])
}
