import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Airdrop, AirdropState } from 'state/types';
import { getAirdropData } from './helpers';

const emptyAirdrop: Airdrop = {
  total: null,
  claimable: null,
  flipPlayed: null,
  dicePlayed: null,
}

const initialState: AirdropState = {
  main: emptyAirdrop,
  player: emptyAirdrop,
  apeswap: emptyAirdrop,
  ama: emptyAirdrop,
}

// Thunks
export const loadAirdropState = createAsyncThunk<AirdropState, string>('airdrop/load', getAirdropData)

export const airdropSlice = createSlice({
  name: 'airdrop',
  initialState,
  reducers: {
  },
  extraReducers: (builder) => {
    // Initialize dice
    builder.addCase(loadAirdropState.pending, (state, action) => {
      return {
        main: emptyAirdrop,
        player: emptyAirdrop,
        apeswap: emptyAirdrop,
        ama: emptyAirdrop,
      }
    })
    builder.addCase(loadAirdropState.fulfilled, (state, action) => {
      return {
        ...state,
        ...action.payload,
      }
    })
  },
})


// Actions
export default airdropSlice.reducer
