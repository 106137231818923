import { ALL_BANKS } from 'config/constants/betTableConfigs';
import { BankState } from '../types';
import { getAddress } from '../../utils/addressHelpers';
import { multicallv2 } from '../../utils/multicall';
import diceAbi from '../../config/abi/dice.json';
import { getBalanceNumber } from '../../utils/formatBalance';

export const getBanksData = async (account: string): Promise<{ [key: number]: BankState }> => {
  const bankStates: { [key: number]: BankState } = ALL_BANKS.reduce((acc, bank) => {
    const config = {
      puased: true,
      bankerTvl: 0,
      bankerCap: 0,
      withdrawFeeRatio: null,
      canWithdrawTokenAmount: null,
    }
    return { ...acc, [bank.tableId]: config }
  }, {})
  const methods = ['paused', 'bankerAmount', 'maxBankerAmount']
  const userMethods = [
    'getWithdrawFeeRatio', // withdrawFeeRatio
    'canWithdrawToken', // canWithdrawTokenAmount
  ];
  const staticDCCalls = ALL_BANKS.reduce((acc, bank) => {
    const address = getAddress(bank.contractAddress)
    const calls = methods.map(method => ({
      address,
      name: method,
      params: [],
    }))
    if(account){
      const userCalls = userMethods.map(name => ({name, address, params: [account]}));
      calls.push(...userCalls);
    }
    return [...acc, ...calls]
  }, [])
  
  const multiCallDCResults = await multicallv2(diceAbi, staticDCCalls)
  ALL_BANKS.forEach((config, index) => {
    const i = index * (account ? (methods.length + userMethods.length) : methods.length)
    bankStates[config.tableId].paused = multiCallDCResults[i]
    bankStates[config.tableId].bankerTvl = getBalanceNumber(multiCallDCResults[i + 1])
    bankStates[config.tableId].bankerCap = getBalanceNumber(multiCallDCResults[i + 2])
    if(account){
      bankStates[config.tableId].withdrawFeeRatio = getBalanceNumber(multiCallDCResults[i + 3])
      bankStates[config.tableId].canWithdrawTokenAmount = multiCallDCResults[i + 4].toString();
    }
  })

  return bankStates
}